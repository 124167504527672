export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าหลัก"])}
  },
  "errors": {
    "no_orders_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบคำสั่งซื้อ"])},
    "no_records_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่พบบันทึก"])}
  },
  "keypad": {
    "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กล่อง"])},
    "i_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไอ-บ็อกซ์"])},
    "reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้อนกลับ"])},
    "roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ม้วน"])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดิมพัน"])}
  },
  "button": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])}
  },
  "modals": {
    "select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกภาษา"])}
  },
  "home": {
    "warm_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำเตือน ,เงินที่ชนะจะถูกฝาก"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมดุล"])},
    "bet_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดิมพัน 1"])},
    "bet_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เดิมพัน 2"])},
    "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายรับ"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลลัพธ์"])},
    "strike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โจมตี"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การจ่ายเงิน"])}
  },
  "receipt": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])}
  },
  "results": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
    "starters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สตาร์ทเตอร์"])},
    "consolation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปลอบใจ"])}
  },
  "strike": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค้นหา"])}
  },
  "instructions": {
    "add_bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คลิกปุ่ม ✚ เพื่อเพิ่มหมายเลขลงในรายการ"])}
  }
}
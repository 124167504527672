<template>
    <div class='home-new'>
        <BackButton title="Payout" />
        <Balance />
        <!-- <div class='playout-con' >
            <div class='playout-table-con'>
                <table>
                    <tr>
                        <th colspan="6" class="table-header"><span>4D</span><span>B</span><span>S</span><span>SA</span></th>
                        <th colspan="5" class="table-header"><span>GD</span><span>B</span><span>S</span><span>SA</span></th>
                    </tr>
                    <tr class='tr-first'>
                        <td>1st</td>
                        <td></td>
                        <td>3500</td>
                        <td>5000</td>
                        <td></td>
                        <td>8000</td>
                        <td></td>
                        <td>2500</td>
                        <td>3500</td>
                        <td></td>
                        <td>6000</td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td></td>
                        <td>1200</td>
                        <td>2400</td>
                        <td>4B</td>
                        <td>8000</td>
                        <td></td>
                        <td>1000</td>
                        <td>2000</td>
                        <td>4B</td>
                        <td>6000</td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td></td>
                        <td>600</td>
                        <td>1200</td>
                        <td>4C</td>
                        <td>8000</td>
                        <td></td>
                        <td>500</td>
                        <td>1000</td>
                        <td>4C</td>
                        <td>6000</td>
                    </tr>
                    <tr>
                        <td>SPC</td>
                        <td></td>
                        <td>250</td>
                        <td></td>
                        <td>4D</td>
                        <td>800</td>
                        <td></td>
                        <td>200</td>
                        <td></td>
                        <td>4D</td>
                        <td>600</td>
                    </tr>
                    <tr>
                        <td>CON</td>
                        <td></td>
                        <td>80</td>
                        <td></td>
                        <td>4E</td>
                        <td>800</td>
                        <td></td>
                        <td>60</td>
                        <td></td>
                        <td>4E</td>
                        <td>600</td>
                    </tr>
                </table>
            </div>
            <div class='playout-table-con'>
                <table>
                    <tr>
                        <th colspan="6" class="table-header"><span>3D</span><span>F</span></th>
                        <th colspan="5" class="table-header"><span>3D</span><span>F</span></th>
                    </tr>
                    <tr class='tr-first'>
                        <td>1st</td>
                        <td>3A</td>
                        <td>840</td>
                        <td>280</td>
                        <td></td>
                        <td></td>
                        <td>3A</td>
                        <td>660</td>
                        <td>250</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td>3B</td>
                        <td>840</td>
                        <td>280</td>
                        <td></td>
                        <td></td>
                        <td>3B</td>
                        <td>660</td>
                        <td>250</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td>3C</td>
                        <td>840</td>
                        <td>280</td>
                        <td></td>
                        <td></td>
                        <td>3C</td>
                        <td>660</td>
                        <td>150</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>SPC</td>
                        <td>3D</td>
                        <td>84</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>3D</td>
                        <td>66</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CON</td>
                        <td>3E</td>
                        <td>84</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>3E</td>
                        <td>66</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class='playout-table-con'>
            <table>
                    <tr>
                        <th colspan="6" class="table-header"><span>2D</span><span>F</span></th>
                        <th colspan="5" class="table-header"><span>2D</span><span>F</span></th>
                    </tr>
                    <tr class='tr-first'>
                        <td>1st</td>
                        <td>2A</td>
                        <td>3500</td>
                        <td>5000</td>
                        <td></td>
                        <td></td>
                        <td>2A</td>
                        <td>2500</td>
                        <td>3500</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td>2B</td>
                        <td>1200</td>
                        <td>2400</td>
                        <td></td>
                        <td></td>
                        <td>2B</td>
                        <td>1000</td>
                        <td>2000</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td>2C</td>
                        <td>600</td>
                        <td>1200</td>
                        <td></td>
                        <td></td>
                        <td>2C</td>
                        <td>500</td>
                        <td>1000</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>SPC</td>
                        <td>2D</td>
                        <td>250</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>2D</td>
                        <td>200</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CON</td>
                        <td>2E</td>
                        <td>80</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>2E</td>
                        <td>60</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class='playout-table-con'>
            <table>
                    <tr>
                        <th colspan="6" class="table-header"><span>TOTO</span><span>5D</span><span>6D</span></th>
                        <th colspan="5" class="table-header"><span>6D</span></th>
                    </tr>
                    <tr class='tr-first'>
                        <td>1st</td>
                        <td></td>
                        <td>16500</td>
                        <td>110000</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>150000</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td></td>
                        <td>5500</td>
                        <td>3300</td>
                        <td></td>
                        <td></td>
                         <td></td>
                        <td>5000</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td></td>
                        <td>3300</td>
                        <td>330</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>500</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>4th</td>
                        <td></td>
                        <td>550</td>
                        <td>33</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>50</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>5th</td>
                        <td></td>
                        <td>22</td>
                        <td>4.4</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>5</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>5th</td>
                        <td></td>
                        <td>5.5</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
        </div> -->
        <div class="playout-con playout-tb">
            <img class="playout-table" src ="@/assets/images/马票1-payout-adjusted.png" />
        </div>
        <div class='payout-icon' >
          <img src="@/assets/images/logo/products/magnum.png" />
          <img src="@/assets/images/logo/products/damacai.png" />
          <img src="@/assets/images/logo/products/sportstoto.png" />
          <img src="@/assets/images/logo/products/sg4d.png" />
          <img src="@/assets/images/logo/products/88.png" />
          <img src="@/assets/images/logo/products/stc.png" />
          <img src="@/assets/images/logo/products/cashsweep.png" />
          <img src="@/assets/images/logo/products/gd.png" />
          <img src="@/assets/images/logo/products/logo-nl9.png" />
          
        </div>
    </div>
</template>
    b
<script>
  import BackButton from '@/components/headers/BackButton.vue'
  import Balance from '@/components/headers/Balance.vue'

  export default {
      name: "Payout",
      components: {BackButton, Balance},
      data() {

      },
      methods: {
        
      },
      mounted: function () {
        
      }
  };
</script>
<template>
    <div class='method-one-content'>
        <div class='left-logo-method'>
          <div @click="getBalance" >
            <i class="fa-solid fa-arrow-rotate-right"></i>
            <span>{{userBalance}}</span>
          </div>
        </div>
        
        <div>
          <router-link to="/home">{{ $t('nav.home') }}</router-link>
        </div>
    </div>
</template>
<script>
export default {
  name: 'Balance',
  data() {
    return {
        userBalance:0
    }
  },
  methods: {
    async getBalance() {

        let _this = this;

        await this.$axios.post('/web/user/getLatestBalance',{}).then(function(response){
            _this.userBalance = parseFloat(response.data.return.userBalance).toFixed(2);
        });
    }
  },
  mounted: function () {
        this.getBalance();
      }
}
</script>
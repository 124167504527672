<template>
    <div class='home-new'>
        <BackButton />
        <!-- <Balance /> -->
        <div >
        <div class="new-header">
            Group Orders
        </div>
        <!-- <div :class="{'search-option-box  expand-option':searchOption, 'search-option-box':!searchOption }"> -->
            <div class='search-option-header' @click="setSearchOption">
                <i class="fa-solid fa-magnifying-glass"></i>
                <span>{{$t('receipt.search')}}</span>
            </div>

            <!-- <div class='search-option-body'>
                <div class='date-option-con'>
                  
                    <div class='date-option'>
                        <input type="radio" id="order-date" value="order" v-model="searchType" />
                        <label for="order-date">Order Date</label>
                    </div>
                    <div class='date-option'>
                        <input type="radio" id="draw-date" value="open" v-model="searchType" />
                        <label for="draw-date">Draw Date</label>
                    </div>
                </div>
                <div class='date-form'>
                    <span class='left-date'>Date From:</span>
                    <input class='right-date' type="date" v-model="dateFrom" />
                </div>
                <div class='date-form'>
                    <span class='left-date'>Date To:</span>
                    <input class='right-date' type="date" v-model="dateTo" />
                </div>
                <div class='date-btn-search'>
                    <button @click="searchOrder">{{$t('button.search')}} <template v-if="!!loadingSearch" ><i class="fas fa-spinner fa-spin"></i></template></button>
                </div>
            </div> -->
        </div>
        <div class="search-result-con" >
            <div class=search-results v-for="order in orderList" :key="order.order_id" >
               
                <p class="search-created-date"><span class="search-order-number"> {{ formatDate(order.order_created_datetime) }}</span> <span @click="viewDetails(order.order_group_unique_key)" class="angle-right"><i class="fa-solid fa-angle-right"></i></span></p>

                <p>{{ order.order_group_number }}</p>
                <!-- <p class="search-draw-date">{{ order.order_open_date }}</p> -->
                <p class="search-status-cancel"><span class="search-order-status">{{ order.order_total_price }}</span>  <span v-if="order.canCancel == true" @click="showCancel(order.order_group_unique_key)" class="search-cancel">Cancel</span></p>
            </div>

            <!-- <ul>
                <li :class="{ 'receipt-cancel':(order.order_status=='canceled') }" v-for="order in orderList" :key="order.order_id" @click="viewDetails(order.order_unique_key)" >
                    <p>{{order.order_created_datetime}} <span><i class="fa-solid fa-angle-right"></i></span></p>
                </li>
            </ul> -->
            <p v-if="orderList.length == 0" >{{ $t('errors.no_orders_found') }}</p>
        </div>
    </div>

    <vue-final-modal v-model="showCancelModal">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                    </div>
                    <div class="modal-body">
                        <h2 class="text-center" ><strong>Are you sure you?</strong></h2>
                        <h5 class="text-center"> This action cannot be undone</h5>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary custom-primary-button" @click="cancelOrder(order_key)" >Yes</button>
                        <button type="button" class="btn btn-secondary custom-secondary-button" @click="showCancelModal = false" >Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>

    <vue-final-modal v-model="showDateFilter" :max-height="100" :click-to-close="true">
        <div class="modal date-filter" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Filter</h5>
                        <img @click="showDateFilter = false" class="close-filter" src="@/assets/images/icon_x.png">
                    </div>
                    <div class="modal-body">
                        <!-- <h2 class="text-center" ><strong>Are you sure you?</strong></h2>
                        <h5 class="text-center"> This action cannot be undone</h5> -->
                        <div class='search-option-body'>
                            <h6><strong>Type</strong></h6>
                            <div class='date-option-con'>
                               
                                <div class='date-option'>
                                    <input type="radio" id="order-date" value="order" v-model="searchType" />
                                    <label for="order-date">Order Date</label>
                                </div>
                                <div class='date-option'>
                                    <input type="radio" id="draw-date" value="open" v-model="searchType" />
                                    <label for="draw-date">Draw Date</label>
                                </div>
                            </div>
                            <h6><strong>Date</strong></h6>
                            <div class="search-date-range">
                                <div class='date-form'>
                                <!-- <span class='left-date'>Date From:</span> -->
                                <input class='right-date' type="date" v-model="dateFrom" />
                                </div>
                                <div> to </div>
                                <div class='date-form'>
                                    <!-- <span class='left-date'>Date To:</span> -->
                                    <input class='right-date' type="date" v-model="dateTo" />
                                </div>
                            </div>
                            
                           
                        </div>
                    </div>
                    <div class="modal-footer">
                        <!-- <div class='date-btn-search'>
                                <button type="button" @click="searchOrder">{{$t('button.search')}} <template v-if="!!loadingSearch" ><i class="fas fa-spinner fa-spin"></i></template></button>
                        </div> -->
                        <button type="button" class="btn btn-primary custom-primary-button" @click="searchOrder">Save<template v-if="!!loadingSearch" ><i class="fas fa-spinner fa-spin"></i></template></button>
                        <!-- <button type="button" class="btn btn-secondary custom-secondary-button" @click="showDateFilter = false" >Cancel</button> -->
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>

</template>

<script>
  import BackButton from '@/components/headers/BackButton.vue'
//   import Balance from '@/components/headers/Balance.vue'
  import moment from 'moment'
  import { exit } from 'process';

  export default {
      name: "Receipt",
    //   components: {BackButton, Balance},
    components: {BackButton},
      
      data() {
        return {
            searchOption:true,
            searchType:"order",
            dateFrom: moment(new Date()).format('YYYY-MM-DD'),
            dateTo: moment(new Date()).format('YYYY-MM-DD'),
            orderList:[],
            loadingSearch:false,
            showCancelModal:false,
            order_group_key:'',
            showDateFilter:false,
        }
      },
      methods: {
        async cancelOrder(order_group_key) {
            let _this = this;
            _this.loadingSubmit=true;
            console.log(this.$route.params.order_group_key);
            // console.log('orderdate',_this.orderDate);
            let date_from = this.dateFrom;
            let date_to = this.dateTo;
            console.log('gunique',order_group_key)
            exit;
            this.$axios.post('/web/ticket/cancelGroupOrder',{
                data: {
                    groupUniqueKey: order_group_key
                }
            }).then(function(response){
                _this.loadingSubmit = false;
                // var date_from = this.$route.params.date_from;
                // var date_to = this.$route.params.date_to;
                _this.$toast.success(response.data.msg,{
                    position:"top"
                });
                _this.$router.push('/group-orders/'+date_from+'/'+date_to);
                _this.showCancelModal = false;
            });
        },
        setSearchOption()  {
            this.searchOption = !this.searchOption;
            this.showDateFilter = !this.showDateFilter;
        },
        searchOrder(from_date_param,to_date_param) {
            let _this = this;
            let from_date;
            let to_date;
            // from_date = moment(new Date(this.dateFrom)).format('YYYY-MM-DD');
            // to_date = moment(new Date(this.dateTo)).format('YYYY-MM-DD');
            // if(from_date_param == moment(new Date()).format('YYYY-MM-DD')  && to_date_param == moment(new Date()).format('YYYY-MM-DD')){
            //     from_date = from_date_param;
            //     to_date = to_date_param;
            //     console.log('not_today');
            // }
            if(from_date_param.isTrusted){
                from_date = moment(new Date(this.dateFrom)).format('YYYY-MM-DD');
                to_date = moment(new Date(this.dateTo)).format('YYYY-MM-DD');
               
            }else{
                from_date = from_date_param;
                to_date = to_date_param;
            }
            console.log('from_date',from_date);
            console.log('from_to',to_date)
            this.$axios.post('/web/ticket/orderGroupHistory',{
                 data:{
                    status:"",
                    fromDate:from_date,
                    toDate:to_date,
                    searchType:this.searchType
                }
             }).then(function(response){

                console.log("responseGroup",response);
                _this.orderList = response.data.return.orders;

    
                
                // _this.setLoadingSearch();
                console.log("_this.orderList", _this.orderList);
                console.log("_this.loadingSearch", _this.loadingSearch);
                _this.showDateFilter = false;
                _this.$toast.success(response.data.msg,{
                    position:"top"
                });
                
            });
        },
        setLoadingSearch() {
            this.loadingSearch = false;
        },
        viewDetails(group_key) {
            var from_date = moment(new Date(this.dateFrom)).format('YYYY-MM-DD');
            var from_to = moment(new Date(this.dateTo)).format('YYYY-MM-DD');
            console.log(from_date);
            console.log(from_to);
            console.log(group_key);
            // this.$router.push({
            //     name: "Receipt",
            //     params: { 
            //         today:from_date,
            //         date_to: from_to,
            //         group_key:group_key,
                    
            //      }
            // });

            this.$router.push('/receipt/'+from_date+'/'+from_to+'/'+group_key);
        },
        formatDate(order_date){
            const date = new Date(order_date)
            const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
            return date.toLocaleDateString('en-US', options);
            // return date;
        },
        showCancel(order_key){
            this.showCancelModal = true;
            this.order_key = order_key;
            console.log(order_key);
        }
      },
      mounted: function () {
        let today = this.$route.params.today;
        let date_to = this.$route.params.date_to;
        this.searchOrder(today,date_to);
      }
  };
</script>
<template>
    <div class='home-new'>
        <BackButton  />
        <div class="new-header">
            Ticket
        </div>
        <!-- <Balance /> -->
    </div>
    
        <!-- <button  class="btn-cancel" v-if="order?.order_status == 'confirmed' && expiredOrder != true" @click="showCancelModal = true" >Cancel Order</button>
        <p>{{ order?.order_number }}</p> -->


        <template v-for="(ticket, ticket_date) of ticketDetails" :key="ticket_date" >
            <template v-for="(ticket_data,index) of ticket.ticket" :key="ticket_data" >
                <div class='receipt-detail-con receipt-details-new'>  
                    <div class="ticket-details">
                        <p class="ticket-number">Ticket {{ index+1  }}</p>
                        <div class="ticket-date-status">
                            <p class="ticket-date"> {{ formattedDateMDY(ticket_data?.ticket_date) }}</p>
                            <p class="ticket-status" v-bind:class="{ 'order-stat-received' : ticket_data?.order_status === 'received', 'order-stat-cancelled' : ticket_data?.ticket_status === 'canceled', 'order-stat-cancelled' : ticket_data?.ticket_status === 'void' }"> {{ ticket_data?.ticket_status }}</p>
                        </div>
                    </div> 
                </div>  

                <div class='receipt-detail-con receipt-legend'>
                    <div class="receipt-legend">
                        <p>Legend</p>
                            <img v-if="ticket_data.legend_code == 'M'" class="preview-log-img" src="@/assets/images/logo/products/magnum.png" />
                            <img v-if="ticket_data.legend_code == 'P'" class="preview-log-img" src="@/assets/images/logo/products/damacai.png" />
                            <img v-if="ticket_data.legend_code == 'T'" class="preview-log-img" src="@/assets/images/logo/products/sportstoto.png" />
                            <img v-if="ticket_data.legend_code == 'S'" class="preview-log-img" src="@/assets/images/logo/products/sg4d.png" />
                            <img v-if="ticket_data.legend_code == 'B'" class="preview-log-img" src="@/assets/images/logo/products/88.png" />
                            <img v-if="ticket_data.legend_code == 'K'" class="preview-log-img" src="@/assets/images/logo/products/stc.png" />
                            <img v-if="ticket_data.legend_code == 'W'" class="preview-log-img" src="@/assets/images/logo/products/cashsweep.png" />
                            <img v-if="ticket_data.legend_code == 'H'" class="preview-log-img" src="@/assets/images/logo/products/gd.png" />
                            <img v-if="ticket_data.legend_code == 'L'" class="preview-log-img" src="@/assets/images/logo/products/logo-nl9.png" />
                    </div>
                </div>

                <div class='receipt-detail-con receipt-details-new' >
                    <div class="num-receipt">
                        
                        <div class="ticket-numbers" style="display:none">
                            <p class="numbers">Numbers</p>
                            <p class="legend">
                                <img v-if="ticket_data.legend_code == 'M'" class="preview-log-img" src="@/assets/images/logo/products/magnum.png" />
                                <img v-if="ticket_data.legend_code == 'P'" class="preview-log-img" src="@/assets/images/logo/products/damacai.png" />
                                <img v-if="ticket_data.legend_code == 'T'" class="preview-log-img" src="@/assets/images/logo/products/sportstoto.png" />
                                <img v-if="ticket_data.legend_code == 'S'" class="preview-log-img" src="@/assets/images/logo/products/sg4d.png" />
                                <img v-if="ticket_data.legend_code == 'B'" class="preview-log-img" src="@/assets/images/logo/products/88.png" />
                                <img v-if="ticket_data.legend_code == 'K'" class="preview-log-img" src="@/assets/images/logo/products/stc.png" />
                                <img v-if="ticket_data.legend_code == 'W'" class="preview-log-img" src="@/assets/images/logo/products/cashsweep.png" />
                                <img v-if="ticket_data.legend_code == 'H'" class="preview-log-img" src="@/assets/images/logo/products/gd.png" />
                                <img v-if="ticket_data.legend_code == 'L'" class="preview-log-img" src="@/assets/images/logo/products/logo-nl9.png" />
                            </p>
                            <p class="number"> 
                                {{ticket_data.ticket_number }}
                            </p>
                                <!-- <div v-if="ticket_data.ticket_b_reject != null || parseFloat(ticket_data.ticket_b_reject) != 0">
                                <p v-if="(parseFloat(ticket_data.ticket_b) - parseFloat(ticket_data.ticket_b_reject)) > 0" >
                                    <b>b = {{ parseFloat(ticket_data.ticket_b) - parseFloat(ticket_data.ticket_b_reject) }}</b>
                                    </p>
                                </div>
                                <div v-else-if="ticket_data.ticket_b_reject == null || parseFloat(ticket_data.ticket_b_reject) == 0">
                                    <p if="parseFloat(ticket_data.ticket_b_reject) > 0">
                                        <b>b = {{ parseFloat(ticket_data.ticket_b_reject) }}(SO)</b>
                                    </p>
                                </div> -->

                            <p v-if="(ticket_data.ticket_b_reject == null || parseFloat(ticket_data.ticket_b_reject) == 0) && parseFloat(ticket_ticket_b) == 0"></p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_b) - parseFloat(ticket_data.ticket_b_reject)) > 0" >
                                b = {{ parseFloat(ticket_data.ticket_b) - parseFloat(ticket_data.ticket_b_reject) }}
                            </p>
                            <p v-else>
                                <!-- <b>b = {{ parseFloat(ticket_data.ticket_b_reject) }}(SO)</b> -->
                            </p>
                            <p v-if="ticket_data.ticket_s_reject == null || parseFloat(ticket_data.ticket_s_reject) == 0"></p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_s) - parseFloat(ticket_data.ticket_s_reject)) > 0" >
                                s = {{ parseFloat(ticket_data.ticket_s) - parseFloat(ticket_data.ticket_s_reject) }}
                            </p>
                            <p v-else>
                                <!-- <b>s = {{ parseFloat(ticket_data.ticket_s_reject) }}(SO)</b> -->
                            </p>
                            <p v-if="(ticket_data.ticket_sa_reject == null || parseFloat(ticket_data.ticket_sa_reject) == 0 ) && parseFloat(ticket_data.ticket_sa)  == 0" ></p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sa) - parseFloat(ticket_data.ticket_sa_reject)) > 0" >
                            sa = {{ (parseFloat(ticket_data.ticket_sa) - parseFloat(ticket_data.ticket_sa_reject)) }}
                            </p>
                            <p v-else>
                                <!-- <b>sa = {{ parseFloat(ticket_data.ticket_sa_reject) }}(SO)</b> -->
                            </p>
                            <p v-if="(ticket_data.ticket_sb_reject == null || parseFloat(ticket_data.ticket_sb_reject) == 0 ) && parseFloat(ticket_data.ticket_sb)  == 0">
                            </p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sb) - parseFloat(ticket_data.ticket_sb_reject)) > 0" >
                                sb = {{ (parseFloat(ticket_data.ticket_sb) - parseFloat(ticket_data.ticket_sb_reject)) }}
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_sb_reject) > 0">
                                <!-- <b>sb = {{ parseFloat(ticket_data.ticket_sb_reject) }}(SO)</b> -->
                            </p>
                            <p v-if="(ticket_data.ticket_sc_reject == null || parseFloat(ticket_data.ticket_sc_reject) == 0 ) && parseFloat(ticket_data.ticket_sc)  == 0" >
                            
                            </p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sc) - parseFloat(ticket_data.ticket_sc_reject)) > 0" >
                            sc = {{ (parseFloat(ticket_data.ticket_sc) - parseFloat(ticket_data.ticket_sc_reject)) }}
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_sc_reject) > 0">
                                <!-- <b>sc = {{ parseFloat(ticket_data.ticket_sc_reject) }}(SO)</b> -->
                            </p>
                            <p v-if="(ticket_data.ticket_sd_reject == null || parseFloat(ticket_data.ticket_sd_reject) == 0) && parseFloat(ticket_data.ticket_sd)  == 0" > </p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sd) - parseFloat(ticket_data.ticket_sd_reject)) > 0" >
                            sd = {{ (parseFloat(ticket_data.ticket_sd) - parseFloat(ticket_data.ticket_sd_reject)) }}
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_sd_reject) > 0">
                                <!-- <b>sd = {{ parseFloat(ticket_data.ticket_sd_reject) }}(SO)</b> -->
                            </p>
                            <p v-else-if="(ticket_data.ticket_se_reject == null || parseFloat(ticket_data.ticket_se_reject) == 0) && parseFloat(ticket_data.ticket_se)  == 0"></p>
                            <p v-if="(parseFloat(ticket_data.ticket_se) - parseFloat(ticket_data.ticket_se_reject)) > 0" >
                            se = {{ (parseFloat(ticket_data.ticket_se) - parseFloat(ticket_data.ticket_se_reject)) }}
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_se_reject) > 0">
                                <!-- <b>se = {{ parseFloat(ticket_data.ticket_se_reject) }}(SO)</b> -->
                            </p>
                            <p v-if="(ticket_data.ticket_sf_reject == null || parseFloat(ticket_data.ticket_sf_reject) == 0) && parseFloat(ticket_data.ticket_sf)  == 0" ></p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sf) - parseFloat(ticket_data.ticket_sf_reject)) > 0" >
                            sf = {{ (parseFloat(ticket_data.ticket_sf) - parseFloat(ticket_data.ticket_sf_reject)) }}
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_sf_reject) > 0">
                                <!-- <b>sf = {{ parseFloat(ticket_data.ticket_sf_reject) }}(SO)</b> -->
                            </p>


                            <!-- SO -->
                            <p v-if="ticket_data.ticket_b_reject == null || parseFloat(ticket_data.ticket_b_reject) == 0">
                                <!-- <b>b = {{ parseFloat(ticket_data.ticket_b) }}</b> -->
                            </p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_b) - parseFloat(ticket_data.ticket_b_reject)) > 0" >
                                <!-- <b>b = {{ parseFloat(ticket_data.ticket_b) - parseFloat(ticket_data.ticket_b_reject) }}</b> -->
                            </p>
                            <p v-else>
                                b = {{ parseFloat(ticket_data.ticket_b_reject) }}(SO)
                            </p>
                            <p v-if="ticket_data.ticket_s_reject == null || parseFloat(ticket_data.ticket_s_reject) == 0">
                                <!-- <b>s = {{ parseFloat(ticket_data.ticket_s) }}</b> -->
                            </p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_s) - parseFloat(ticket_data.ticket_s_reject)) > 0" >
                                <!-- <b>s = {{ parseFloat(ticket_data.ticket_s) - parseFloat(ticket_data.ticket_s_reject) }}</b> -->
                            </p>
                            <p v-else>
                            s = {{ parseFloat(ticket_data.ticket_s_reject) }}(SO)
                            </p>
                            <p v-if="ticket_data.ticket_sa_reject == null || parseFloat(ticket_data.ticket_sa_reject) == 0 " >
                                <!-- <b>sa = {{ parseFloat(ticket_data.ticket_sa) }}</b> -->
                            </p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sa) - parseFloat(ticket_data.ticket_sa_reject)) > 0" >
                                <!-- <b>sa = {{ (parseFloat(ticket_data.ticket_sa) - parseFloat(ticket_data.ticket_sa_reject)) }}</b> -->
                            </p>
                            <p v-else>
                            sa = {{ parseFloat(ticket_data.ticket_sa_reject) }}(SO)
                            </p>
                            <p v-if="ticket_data.ticket_sb_reject == null || parseFloat(ticket_data.ticket_sb_reject) == 0">
                            </p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sb) - parseFloat(ticket_data.ticket_sb_reject)) > 0" >
                                <!-- <b>ticket_sb = {{ (parseFloat(ticket_data.ticket_sb) - parseFloat(ticket_data.ticket_sb_reject)) }}</b> -->
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_sb_reject) > 0">
                                sb = {{ parseFloat(ticket_data.ticket_sb_reject) }}(SO)
                            </p>
                            <p v-if="ticket_data.ticket_sc_reject == null || parseFloat(ticket_data.ticket_sc_reject) == 0">
                            
                            </p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sc) - parseFloat(ticket_data.ticket_sc_reject)) > 0" >
                                <!-- <b>ticket_sc = {{ (parseFloat(ticket_data.ticket_sc) - parseFloat(ticket_data.ticket_sc_reject)) }}</b> -->
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_sc_reject) > 0">
                            sc = {{ parseFloat(ticket_data.ticket_sc_reject) }}(SO)
                            </p>
                            <p v-if="ticket_data.ticket_sd_reject == null || parseFloat(ticket_data.ticket_sd_reject) == 0"> </p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sd) - parseFloat(ticket_data.ticket_sd_reject)) > 0" >
                                <!-- <b>sd = {{ (parseFloat(ticket_data.ticket_sd) - parseFloat(ticket_data.ticket_sd_reject)) }}</b> -->
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_sd_reject) > 0">
                            sd = {{ parseFloat(ticket_data.ticket_sd_reject) }}(SO)
                            </p>
                            <p v-else-if="ticket_data.ticket_se_reject == null || parseFloat(ticket_data.ticket_se_reject) == 0"></p>
                            <p v-if="(parseFloat(ticket_data.ticket_se) - parseFloat(ticket_data.ticket_se_reject)) > 0" >
                                <!-- <b>se = {{ (parseFloat(ticket_data.ticket_se) - parseFloat(ticket_data.ticket_se_reject)) }}</b> -->
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_se_reject) > 0">
                            se = {{ parseFloat(ticket_data.ticket_se_reject) }}(SO)
                            </p>
                            <p v-if="ticket_data.ticket_sf_reject == null || parseFloat(ticket_data.ticket_sf_reject) == 0"></p>
                            <p v-else-if="(parseFloat(ticket_data.ticket_sf) - parseFloat(ticket_data.ticket_sf_reject)) > 0" >
                                <!-- <b>sf = {{ (parseFloat(ticket_data.ticket_sf) - parseFloat(ticket_data.ticket_sf_reject)) }}</b> -->
                            </p>
                            <p v-else-if="parseFloat(ticket_data.ticket_sf_reject) > 0">
                                sf = {{ parseFloat(ticket_data.ticket_sf_reject) }}(SO)
                            </p>
                            <p></p>
                        </div>
                        
                        <div class="ticket-receipt-container">
                            <p class="receipt-header">Receipt</p>
                            <div v-if="ticket_data?.ticket_status != 'received'">
                                 <!-- <p class="ticket-status" v-bind:class="{ 'order-stat-received' : ticket_data?.ticket_status === 'received', 'order-stat-cancelled' : ticket_data?.ticket_status === 'canceled', 'order-stat-cancelled' : ticket_data?.ticket_status === 'void' }">{{  ticket_data?.ticket_status === 'void' ? 'CANCELED': ticket_data?.ticket_status === 'canceled' ? 'CANCELED' : ticket_data?.ticket_status  }}</p> -->
                                <!-- <p>{{ (ticket_data?.ticket_status == 'canceled' || ticket_data?.ticket_status == 'void')  && (ticket_data?.ticket_void_receipt != ' ' || ticket_data?.ticket_void_receipt != null) ? ticket_data?.ticket_void_receipt  : ticket_data?.ticket_receipt }}</p> -->
                                <pre class="pre-receipt" style="">{{ (ticket_data?.ticket_status == 'canceled' || ticket_data?.ticket_status == 'void')  && (ticket_data?.ticket_void_receipt != ' ' || ticket_data?.ticket_void_receipt != null) ? ticket_data?.ticket_void_receipt  : ticket_data?.ticket_receipt }}</pre>
                                <!-- <p class="ticket-receipt">{{  ticket_data.ticket_receipt }}</p> -->
                                <P>{{ tikcetResponse(ticket_data.ticket_response)  }} </P>
                            </div>
                            <div v-else>
                                <p>Pending</p>
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div class='receipt-detail-con receipt-details-new' >
                    <div class="ticket-draw-date">
                        <p>Draw Date</p>
                        <p>{{ formattedDateMDY(ticket_data.ticket_date) }}</p>
                    </div>
                    <div class="ticket-order-date">
                        <p>Order Date</p>
                        <p>{{ formatDate(ticket_data.ticket_created_datetime) }}</p>
                    </div>
                    <div class="ticket-order-status ticket-order-date">
                        <p>Type</p>
                        <p>{{ methodType(ticket_data.method_id) }}</p>
                    </div>
                     <div class="ticket-order-status ticket-order-date">
                        <p>Total</p>
                        <p>{{  formattedNumber(formattedNumber(ticket_data.ticket_total_price) - formattedNumber(ticket_data.ticket_total_reject)) }}</p>
                    </div>
                </div>

                <div class='receipt-detail-con receipt-details-new ticket-progress' >
                    <template v-for="(timeline_data, timeline_index) in ticket_data.timelines" :key="timeline_index">
                        <div class="progress-sumbitted" v-bind:class="{ 'progress-bar-timeline': timeline_index != 0 }">
                            <div class="icon-text">
                                <i class="fa-solid fa-circle-check" style="color: #edb53d;"></i>
                                <div class="text">
                                    <p class="progress-stat" v-bind:class="{ 'status-confirmed' : timeline_data?.timeline_action === 'confirmed', 'status-cancelled' : timeline_data?.timeline_action === 'canceled',  'status-pending' : timeline_data?.timeline_action === 'pending' }">{{timeline_data.timeline_action}}</p>
                                    <p>{{ formatDate(timeline_data.timeline_datetime) }}</p>
                                </div>
                            </div>  
                        </div>
                    </template>
                    <!-- <div class="progress-sumbitted">
                        <div class="icon-text">
                            <i class="fa-solid fa-circle-check" style="color: #edb53d;"></i>
                            <div class="text">
                                <p class="progress-stat">Submitted</p>
                                <p>{{ formatDate(order.order_created_datetime) }}</p>
                            </div>
                        </div>    
                    </div>
                    <div class="progress-processing">
                        <div class="icon-text">
                            <i class="fa-solid fa-circle-check" style="color: #edb53d;"></i>
                            <div class="text">
                                <p class="progress-stat">Processing</p>
                            <p>{{  formattedDateMDY(order.order_created_datetime) }}</p>
                            </div>
                        </div>    
                    </div>
                    <div class="progress-completed" style="display:none;">
                        <div class="icon-text">
                            <i class="fa-solid fa-circle-check" style="color: #edb53d;"></i>
                            <div class="text">
                                <p class="progress-stat">Completed</p>
                                <p>Mar 14, 2023</p>
                            </div>
                        </div>    
                    </div> -->
                </div>

                <div class='receipt-detail-con receipt-cancel'>
                    <!-- @click="showCancelModal = true"  -->
                    <button  class="btn--receipt-cancel" v-if="ticket_data?.ticket_status == 'confirmed' && expiredOrder != true"  @click="cancelTicket(ticket_data.ticket_unique_key)">Cancel Order</button>
                </div>
                
            </template>
            
          
        </template>

        <!-- old -->

    <vue-final-modal v-model="showCancelModal">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                    </div>
                    <div class="modal-body">
                        <h2 class="text-center" ><strong>Are you sure you?</strong></h2>
                        <h5 class="text-center"> This action cannot be undone</h5>
                    </div>
                    <div class="modal-footer">
                        <!-- @click=cancelOrder(unique_id)" -->
                        <button type="button" class="btn btn-primary custom-primary-button" @click="cancelOrder(ticket_unique)" >Yes</button>
                        <button type="button" class="btn btn-secondary custom-secondary-button" @click="showCancelModal = false" >Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
<div v-if="loadingSubmit" class="loadingSubmit">
    <div class="loader"></div>
<p>Loading Please Wait</p>
</div>
</template>
<style type="text/css">

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #EDA526;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loadingSubmit {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #2a2b2ec2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1000;
}
.loadingSubmit  p {
    text-align: center;
    color: #fff;
    /* margin-left: 42px; */
    margin-top: 12px;
}

.pre-receipt{
    font-family: 'Roboto', sans-serif;line-height: 24px; font-size: 16px;
}

</style>
<script>
  import BackButton from '@/components/headers/BackButton.vue'
//   import Balance from '@/components/headers/Balance.vue'
  import moment from 'moment'
import { exit } from 'process';

  export default {
      name: "Receipt Details",
      components: {BackButton},
      data() {
        return {
            order:null,
            ticketDetails:[],
            showCancelModal:false,
            expiredOrder: false,
            loadingSubmit:false,
            ticket_unique:'',
           
        }
      },
      methods: {
        async getOrderDetail(order_key) {
            let _this = this;

            this.$axios.post('/web/ticket/detail',{
                data: {
                    orderUniqueKey: order_key
                }
            }).then(function(response){
                console.log("order",response);
               
                _this.order = response.data.return.order;
                console.log("json",response.data.return.order);
                let order_ticket  = response.data.return.order.tickets;
                var rejected = 0;
                let order_ticket_data = {};
                var today = moment(new Date()).format('YYYY-MM-DD');
                let order_date = response.data.return.order_open_date;
               
              
                if(today > order_date){
                    this.expiredOrder = true;
                }

                order_ticket.forEach(ticket => {
                    var ticket_date = ticket.ticket_date;
                    ticket_date = ticket_date.split("-");
                    ticket_date = ticket_date[1]+""+ticket_date[2];
                    if (typeof order_ticket_data[ticket_date] == "undefined" ) {
                        order_ticket_data[ticket_date] = {
                            legend:[],
                            ticket:[],
                            so:[],
                            total:0
                        }
                    }

                    if(!order_ticket_data[ticket_date].legend.includes(ticket.legend_code))
                    {
                        order_ticket_data[ticket_date].legend.push(ticket.legend_code);
                    }
                    // console.log('tic',ticket)
                    console.log('ticketDetials',ticket.ticket_b_reject);
                    if(ticket.ticket_b_reject > 0){
                        console.log('rejected',order_ticket_data[ticket_date].so.push(ticket.ticket_b_reject));
                        // ticket.ticket_b_reject.push(so);
                        // order_ticket_data[ticket_date].so.push(so);
                    }else{
                        console.log('not');
                    }
                    if(ticket.ticket_total_reject != null){
                        rejected = parseFloat(ticket.ticket_total_reject);
                    }
                   
                    order_ticket_data[ticket_date].total =  (order_ticket_data[ticket_date].total + parseFloat(ticket.ticket_total_price) - rejected);

                    order_ticket_data[ticket_date].ticket.push(ticket);

                    

                });
                console.log('tic',order_ticket_data)
                _this.ticketDetails = order_ticket_data;
                

            });
        },
        formatDate(order_date){
            const date = new Date(order_date)
            const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false };
            return date.toLocaleDateString('en-US', options);
            // return date;
        },
        formattedDateMDY(date) {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString('en-US', options);
        },
        cancelTicket(ticket_unique_id){
            console.log(ticket_unique_id);
            this.showCancelModal= true;
            this.ticket_unique = ticket_unique_id;
        },
        methodType(type_id){
            if(type_id == 1){
                return 'Normal';
            }else if(type_id == 2){
                return 'Box';
            }else if(type_id == 3){
                return 'I-Box';
            }else{
                return 'Reverse';
            }

        },
        tikcetResponse(response){
            // JSON.parse(response);
            const ticketResponse = JSON.parse(response);
            console.log(ticketResponse);
        },
        formattedNumber(number_string) {
            if (number_string) {
                // convert the string to a number and round to 2 decimal places
                    const number = parseFloat(number_string).toFixed(2);
                    // return the formatted number with a comma for thousands separator
                    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    return "";
            }
        },
        async cancelOrder(ticket_unique) {
            let _this = this;
            _this.loadingSubmit=true;
            console.log('ticket_unique_id',ticket_unique);
            console.log(this.$route.params.order_key);
            // console.log('orderdate',_this.orderDate);
            // let date_from = this.$route.params.date_from;
            // let date_to = this.$route.params.date_to;

            exit;
            this.$axios.post('/web/ticket/cancelTicket',{
                data: {
                    ticketUniqueKey: ticket_unique
                }
            }).then(function(response){
                _this.loadingSubmit = false;
                _this.showCancelModal = false;
                
                _this.$toast.success(response.data.msg,{
                    position:"top"
                });
                _this.$router.push('/receipt-details/'+_this.$route.params.order_key);
                let data = _this.$route.params.order_key;
                
                _this.getOrderDetail(data);
                location.reload();
                // _this.$router.push('/receipt-details/'+ticket_unique+'/'+date_to+"/"+ticket_unique);
            });
        }

      },
      mounted: function () {
        let _this = this;
        let data = _this.$route.params.order_key;
        console.log('dataaa',data);
        _this.getOrderDetail(data);

      }
  };
</script>
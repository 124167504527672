export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "errors": {
    "no_orders_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No orders found"])},
    "no_records_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records found"])}
  },
  "keypad": {
    "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOX"])},
    "i_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I-BOX"])},
    "reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVERSE"])},
    "roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROLL"])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BET"])}
  },
  "button": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])}
  },
  "modals": {
    "select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilih Bahasa"])}
  },
  "home": {
    "warm_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warm reminder: The wining money will be deposited"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BAKI"])},
    "bet_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERTARUHAN 1"])},
    "bet_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERTARUHAN 2"])},
    "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESIT"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEPUTUSAN"])},
    "strike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENANG"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PEMBAYARAN"])}
  },
  "receipt": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])}
  },
  "results": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "starters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STARTERS"])},
    "consolation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSOLATION"])}
  },
  "strike": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])}
  },
  "instructions": {
    "add_bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik butang ✚ untuk menambah nombor pada senarai"])}
  }
}
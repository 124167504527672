<template>
    <div class='home-new'>
        <BackButton title="Strike" />
        <Balance />
        <div :class="{'search-option-box  expand-option':searchOption, 'search-option-box':!searchOption }">
            <div class='search-option-header' @click="setSearchOption">
                <i class="fa-solid fa-magnifying-glass"></i>
                <span>{{ $t('strike.search') }}</span>
            </div>

            <div class='search-option-body'>
                <div class='date-option-con'>
                    <!-- <div class='date-option'>
                        <input type="radio" id="draw-date" value="date" v-model="searchType" />
                        <label for="draw-date">Draw Date</label>
                    </div> -->
                    <div class='date-option'>
                        <input type="radio" id="order-date" value="order" v-model="searchType" />
                        <label for="order-date">Order Date</label>
                    </div>
                </div>
                <div class='date-form'>
                    <span class='left-date'>Date From:</span>
                    <input class='right-date' type="date" v-model="dateFrom" />
                </div>
                <div class='date-form'>
                    <span class='left-date'>Date To:</span>
                    <input class='right-date' type="date" v-model="dateTo" />
                </div>
                <div class='date-btn-search'>
                    <button @click="searchOrder">{{$t('button.search')}}</button>
                </div>
            </div>
        </div>
        <div class="receipt-detail-con" >
            <template v-for="order in orderList" :key="order.order_id" >
                <p>{{"("+order.ticket_date+")"}}</p>
                <p>
                    <img v-if="order.legend_code == 'M'" class="preview-log-img" src="@/assets/images/logo/products/magnum.png" />
                    <img v-if="order.legend_code == 'P'" class="preview-log-img" src="@/assets/images/logo/products/damacai.png" />
                    <img v-if="order.legend_code == 'T'" class="preview-log-img" src="@/assets/images/logo/products/sportstoto.png" />
                    <img v-if="order.legend_code == 'S'" class="preview-log-img" src="@/assets/images/logo/products/sg4d.png" />
                    <img v-if="order.legend_code == 'B'" class="preview-log-img" src="@/assets/images/logo/products/88.png" />
                    <img v-if="order.legend_code == 'K'" class="preview-log-img" src="@/assets/images/logo/products/stc.png" />
                    <img v-if="order.legend_code == 'W'" class="preview-log-img" src="@/assets/images/logo/products/cashsweep.png" />
                    <img v-if="order.legend_code == 'H'" class="preview-log-img" src="@/assets/images/logo/products/gd.png" />
                    <img v-if="order.legend_code == 'L'" class="preview-log-img" src="@/assets/images/logo/products/logo-nl9.png" />
                </p>
                <div>
                    <p>
                        {{order.ticket_number }} = {{ order.ticket_b=="0"? "":"B"+order.ticket_b }} {{ order.ticket_s=="0"? "":"S"+order.ticket_s }} {{ order.ticket_sa=="0"? "":"SA"+order.ticket_sa }}
                    </p>
                </div>
                <p className='bet-preview-total'>WON: {{order.ticket_won}}</p>
                <hr/>
            </template>
            <p v-if="orderList.length == 0" >{{$t('errors.no_records_found')}}</p>
        </div>
    </div>
</template>

<script>
  import BackButton from '@/components/headers/BackButton.vue'
  import Balance from '@/components/headers/Balance.vue'
  import moment from 'moment'

  export default {
      name: "Strike",
      components: {BackButton, Balance},
      data() {
        return {
            searchOption:false,
            searchType:"order",
            dateFrom: moment(new Date()).format('YYYY-MM-DD'),
            dateTo: moment(new Date()).format('YYYY-MM-DD'),
            orderList:[]
        }
      },
      methods: {
        setSearchOption()  {
            this.searchOption = !this.searchOption;
        },
        async searchOrder() {
            let _this = this;
            let from_date = moment(new Date(this.dateFrom)).format('YYYY-MM-DD');
            let to_date = moment(new Date(this.dateTo)).format('YYYY-MM-DD');

             await this.$axios.post('/web/ticket/won',{
                 data:{
                    status:"",
                    fromDate:from_date,
                    toDate:to_date,
                    searchType:this.searchType
                }
             }).then(function(response){
                console.log("response",response);
                _this.orderList = response.data.return.tickets;
                console.log("_this.orderList", _this.orderList);
            });
        },
        viewDetails(order_key) {
            
            this.$router.push({
                name: "ReceiptDetails",
                params: { 
                    order_key:order_key
                 }
            });
        }
      },
      mounted: function () {
        
      }
  };
</script>
<template>
    <div class='home-new'>
        <BackButton title="Results" />
        <Balance />
            <div className='result-menu-header'>
                <div className='result-menu' @click="filterResult('all')" >
                    <p>All</p>
                </div>
                <div className='result-menu' v-for="legend of legends" :key="legend.legend_id"  >
                        <img v-if="legend.legend_code == 'M'" @click="filterResult(legend.legend_id,1)" class="result-menu-log-img" :class="{ 'result_menu_img_select':productSelected == 1 }" src="@/assets/images/logo/products/magnum.png" />
                        <img v-if="legend.legend_code == 'P'" @click="filterResult(legend.legend_id,2)" class="result-menu-log-img" :class="{ 'result_menu_img_select':productSelected == 2 }" src="@/assets/images/logo/products/damacai.png" />
                        <img v-if="legend.legend_code == 'T'" @click="filterResult(legend.legend_id,3)" class="result-menu-log-img" :class="{ 'result_menu_img_select':productSelected == 3 }" src="@/assets/images/logo/products/sportstoto.png" />
                        <img v-if="legend.legend_code == 'S'" @click="filterResult(legend.legend_id,4)" class="result-menu-log-img" :class="{ 'result_menu_img_select':productSelected == 4 }" src="@/assets/images/logo/products/sg4d.png" />
                        <img v-if="legend.legend_code == 'B'" @click="filterResult(legend.legend_id,5)" class="result-menu-log-img" :class="{ 'result_menu_img_select':productSelected == 5 }" src="@/assets/images/logo/products/88.png" />
                        <img v-if="legend.legend_code == 'K'" @click="filterResult(legend.legend_id,6)" class="result-menu-log-img" :class="{ 'result_menu_img_select':productSelected == 6 }" src="@/assets/images/logo/products/stc.png" />
                        <img v-if="legend.legend_code == 'W'" @click="filterResult(legend.legend_id,7)" class="result-menu-log-img" :class="{ 'result_menu_img_select':productSelected == 7 }" src="@/assets/images/logo/products/cashsweep.png" />
                        <img v-if="legend.legend_code == 'H'" @click="filterResult(legend.legend_id,8)" class="result-menu-log-img" :class="{ 'result_menu_img_select':productSelected == 8 }" src="@/assets/images/logo/products/gd.png" />
                        <img v-if="legend.legend_code == 'L'" @click="filterResult(legend.legend_id,9)" class="result-menu-log-img" :class="{ 'result_menu_img_select':productSelected == 9 }" src="@/assets/images/logo/products/logo-nl9.png" />
                </div>
            </div>
            <div className='result-list-con'>
                <div className='result-page'>
                    <p className='result-date' >
                        <input  type="date" v-model="resultDate" :onchange="updateDate"/>
                    </p>
                </div>
            </div>

           
            <template v-for="legend of legends" :key="legend.legend_id" >
                
                <template v-if="resultList.length != 0 && resultList[resultDate][legend.legend_code] != null " >
                    <template v-for="(game_result, game_type) of resultList[resultDate][legend.legend_code]" :key="game_type" >
                        <div className='product-win-header' >
                            <img v-if="legend.legend_code == 'M'" class="img-product" src="@/assets/images/logo/products/magnum.png" />
                            <img v-if="legend.legend_code == 'P'" class="img-product" src="@/assets/images/logo/products/damacai.png" />
                            <img v-if="legend.legend_code == 'T'" class="img-product" src="@/assets/images/logo/products/sportstoto.png" />
                            <img v-if="legend.legend_code == 'S'" class="img-product" src="@/assets/images/logo/products/sg4d.png" />
                            <img v-if="legend.legend_code == 'B'" class="img-product" src="@/assets/images/logo/products/88.png" />
                            <img v-if="legend.legend_code == 'K'" class="img-product" src="@/assets/images/logo/products/stc.png" />
                            <img v-if="legend.legend_code == 'W'" class="img-product" src="@/assets/images/logo/products/cashsweep.png" />
                            <img v-if="legend.legend_code == 'H'" class="img-product" src="@/assets/images/logo/products/gd.png" />
                            <img v-if="legend.legend_code == 'L'" class="img-product" src="@/assets/images/logo/products/logo-nl9.png" />
                            <p>{{ legend.legend_name }} ({{ legend.legend_code }}) {{ game_type }} </p>
                            <p>{{ resultDate }}</p>
                        </div>
                        <div className='first-head-win'>
                            <div>1ST</div>
                            <div>2ND</div>
                            <div>3RD</div>
                        </div>
                        <div className='first-head-result'>
                            <div>{{ game_result[0]  != null? game_result[0].number:"--" }}</div>
                            <div>{{ game_result[1]  != null? game_result[1].number:"--" }}</div>
                            <div>{{ game_result[2]  != null? game_result[2].number:"--" }}</div>
                        </div>
                        <div className='first-head-win'>
                            <div>STARTERS</div>
                        </div>
                        <div className='first-head-start'>
                            <template v-if="renderTicketArray(game_result,'drawnumbers').length == 0" >
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                            </template>
                            <div v-for="ticket_data of renderTicketArray(game_result,'drawnumbers')" :key="ticket_data" >
                                {{ ticket_data.number }}
                            </div>

                        </div>
                        <div className='first-head-win'>
                            <div>CONSOLATION</div>
                        </div>
                        <div className='first-head-start'>
                             <template v-if="renderTicketArray(game_result,'drawnumberc').length == 0" >
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                                <div>--</div>
                            </template>
                            <div v-for="ticket_data of renderTicketArray(game_result,'drawnumberc')" :key="ticket_data" >
                                {{ ticket_data.number }}
                            </div>
                        </div>
                    </template>
                </template>
                <template v-if="resultList.length == 0">
                    <div className='product-win-header' >
                        <img v-if="legend.legend_code == 'M'" class="img-product" src="@/assets/images/logo/products/magnum.png" />
                        <img v-if="legend.legend_code == 'P'" class="img-product" src="@/assets/images/logo/products/damacai.png" />
                        <img v-if="legend.legend_code == 'T'" class="img-product" src="@/assets/images/logo/products/sportstoto.png" />
                        <img v-if="legend.legend_code == 'S'" class="img-product" src="@/assets/images/logo/products/sg4d.png" />
                        <img v-if="legend.legend_code == 'B'" class="img-product" src="@/assets/images/logo/products/88.png" />
                        <img v-if="legend.legend_code == 'K'" class="img-product" src="@/assets/images/logo/products/stc.png" />
                        <img v-if="legend.legend_code == 'W'" class="img-product" src="@/assets/images/logo/products/cashsweep.png" />
                        <img v-if="legend.legend_code == 'H'" class="img-product" src="@/assets/images/logo/products/gd.png" />
                        <img v-if="legend.legend_code == 'L'" class="img-product" src="@/assets/images/logo/products/logo-nl9.png" />
                        <p>{{ legend.legend_name }} ({{ legend.legend_code }}) </p>
                        <p>{{ resultDate }}</p>
                    </div>
                    <div className='first-head-win'>
                        <div>1ST</div>
                        <div>2ND</div>
                        <div>3RD</div>
                    </div>
                    <div className='first-head-result'>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                    </div>
                    <div className='first-head-win'>
                        <div>STARTERS</div>
                    </div>
                    <div className='first-head-start'>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                    </div>
                    <div className='first-head-win'>
                        <div>CONSOLATION</div>
                    </div>
                    <div className='first-head-start'>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                        <div>--</div>
                    </div>
                </template>
            </template>
    </div>
</template>

<script>
  import BackButton from '@/components/headers/BackButton.vue'
  import Balance from '@/components/headers/Balance.vue'
  import moment from 'moment'

  export default {
      name: "Results",
      components: {BackButton, Balance},
      data() {
        return {
          legends:[],
          resultList:[],
          resultDate:moment(new Date()).format('YYYY-MM-DD'),
          resultLegendId:"",
          productSelected:0
        }
      },
      methods: {
        async getConfig () {
          let _this = this;
          await this.$axios.post('/web/ticket/getBetConfig',{}).then(function(response){
            _this.legends = response.data.return.channels[0].legends
          });
        },
        async winningResult () {
          let _this = this;
          // let date = moment(new Date()).format('YYYY-MM-DD');
          console.log('date',_this.resultDate);
          console.log('legendID',_this.resultLegendId);
          await this.$axios.post('/web/ticket/result',{
            data: {
              date:_this.resultDate,
              legendId:_this.resultLegendId,
              typeId:""
            }
          }).then(function(response){
            console.log('res', response.data)
            _this.resultList = response.data.return.winningResult;
          });
        },
        renderTicketArray (ticket, ticket_type) {
            let ticket_arr = [];
            ticket.forEach(ticket_data => {

                if(ticket_data.numberType.includes(ticket_type))
                {
                    ticket_arr.push(ticket_data);
                }

            });

            return ticket_arr;
        },
        filterResult (legend_id,event) {
            if(legend_id == "all")
            legend_id = "";

            this.resultLegendId = legend_id;
            this.winningResult();
            this.productSelected = event; 
        },
        updateDate(){
            this.getConfig();
            this.winningResult();
            console.log('test');
        }
      },
      mounted: function () {
        this.getConfig();
        this.winningResult();
      }
  };
</script>
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { vfmPlugin } from 'vue-final-modal'
import Toaster from "@meforma/vue-toaster";
import './assets/css/main.css'
import i18n from './i18n'

let axios_config = {
    baseURL: process.env.VUE_APP_API_URL,
}


if(store.getters.StateUser != null)
{
    axios_config.headers = {
        'Authorization': store.getters.StateUser.userAuthtoken
    }
}


const axiosInstance = axios.create(axios_config)

const app = createApp(App).use(i18n)
app.use(store)
app.use(vfmPlugin)
app.use(Toaster)
app.use(router)
app.config.globalProperties.$axios = { ...axiosInstance }
app.mount('#app')

import Axios from "axios"

const state = {
  user: null,
  posts: null,
  login_token: null,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  StatePosts: (state) => state.posts,
  StateUser: (state) => state.user,
  StateLoginToken: (state) => state.login_token,
};

const actions = {

    async LogIn({commit}, user) {
        console.log("user login",user);
        var res;
        console.log(state.login_token);
        await Axios.post(process.env.VUE_APP_API_URL+"/api/user/login", {
            data:{
                username:user.username
            }
        },{
            headers: {
                'Authorization': user.login_token
              }
        })
        .then(response => {
            console.log('response', response);
            if(response.data.status_code != 400)
            {
              var login_url = new URL("http://"+response.data.return.loginUrl);
              var params = new URLSearchParams(login_url.search);
              var access_token = params.get("key");

              return Axios.post(process.env.VUE_APP_API_URL+"/web/user/directLogin", {
                  data: {
                      token:access_token
                  }
              },{
                  headers: {
                      'Authorization': user.login_token
                    }
              })
              .then(response => {
                  console.log("response.data",response.data);
                  res = response.data.return;
      
              });
            }else
            {
              res = false;
            }

        });
        await commit("setUser", res);
    },

    async generateToken({commit}, auth_token, direct_login = false) {
        
        let res;
        console.log("direct_login", direct_login);
        if(!direct_login)
        {
          await Axios.post(process.env.VUE_APP_API_URL+"/auth/generateAuthToken", {
              token:auth_token
          })
          .then(response => {
              res = response;

              return commit("setLoginToken", res.data.return.token);
          });
        }else
        {
          return commit("setLoginToken", auth_token);
        }
       
       
        // await commit("setLoginToken", res.data.return.token);
        
    },
    async directLogIn({commit}, auth_token) {

      var res;
      
      await Axios.post(process.env.VUE_APP_API_URL+"/web/user/directLogin", {
            data: {
                token:auth_token
            }
        })
        .then(response => {
            console.log("response.data",response.data);
            res = response.data.return;
        });
      
      await commit("setLoginToken", auth_token);
      await commit("setUser", res);
    },

};

const mutations = {
  setUser(state, username) {
    state.user = username;
  },
  setLoginToken(state, login_token) {
    console.log("response login_token",login_token);
    state.login_token = login_token;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import Login from '../views/auth/Login.vue'
import DirectLogin from '../views/auth/DirectLogin.vue'
import Home from '../views/dashboard/Home.vue'
import Receipt from '../views/receipt/Index.vue'
import GroupOrders from '../views/receipt/GroupOrders.vue'
import ReceiptDetails from '../views/receipt/Details.vue'
import Result from '../views/result/Index.vue'
import Strike from '../views/strike/Index.vue'
import Payout from '../views/payout/Index.vue'
import BetOne from '../views/bet/One.vue'
import BetTwo from '../views/bet/Two.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { guest: true },
  },
  {
    path: '/directLogin',
    name: 'DirectLogin',
    component: DirectLogin,
    meta: { guest: true },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '/receipt/:today/:date_to/:group_key',
    name: 'Receipt',
    component: Receipt,
    meta: { requiresAuth: true },
    // props: (route) => ({ today: route.params.today || new Date().toISOString().substr(0, 10) })
  },
  {
    path: '/group-orders/:today/:date_to',
    name: 'GroupOrders',
    component: GroupOrders,
    meta: { requiresAuth: true },
    props: (route) => ({ today: route.params.today || new Date().toISOString().substr(0, 10) })
  },
  {
    path: '/receipt-details/:order_key/',
    name: 'ReceiptDetails',
    props: true,
    component: ReceiptDetails,
    meta: { requiresAuth: true },
  },
  {
    path: '/results',
    name: 'Result',
    component: Result,
    meta: { requiresAuth: true },
  },
  {
    path: '/strike',
    name: 'Strike',
    component: Strike,
    meta: { requiresAuth: true },
  },
  {
    path: '/payout',
    name: 'Payout',
    component: Payout,
    meta: { requiresAuth: true },
  },
  {
    path: '/bet-one',
    name: 'BetOne',
    component: BetOne,
    meta: { requiresAuth: true },
  },
  {
    path: '/bet-two',
    name: 'BetTwo',
    component: BetTwo,
    meta: { requiresAuth: true },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router

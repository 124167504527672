export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页"])}
  },
  "errors": {
    "no_orders_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到订单"])},
    "no_records_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到记录"])}
  },
  "keypad": {
    "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["打字"])},
    "i_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全保"])},
    "reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来回"])},
    "roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["随机"])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下注"])}
  },
  "button": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])}
  },
  "modals": {
    "select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择语言"])}
  },
  "home": {
    "warm_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["温馨提示: 中奖奖金将自动存入"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
    "bet_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投注 1"])},
    "bet_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投注 2"])},
    "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收据"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成绩"])},
    "strike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中奖"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["赔率"])}
  },
  "receipt": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])}
  },
  "results": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "starters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["特别奖"])},
    "consolation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安慰奖"])}
  },
  "strike": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])}
  },
  "instructions": {
    "add_bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击 ✚ 按钮把号码加入列表中"])}
  }
}
<template>
    <p v-if="!showError" >Loading...</p>
    <p v-if="showError" >{{message}}</p>
</template>
<script>
    import {mapActions} from "vuex";
    export default {
        name: "Login",
        components: {},
        data() {
            return {
                form: {
                    username: "",
                    password: "",
                },
                showError: false,
                message:  '',
            };
        },
        methods: {
            ...mapActions(["directLogIn"]),
            async login() {
                let _this = this;
                try {
                    var auth_token = this.$route.query.key;
                    await this.directLogIn(auth_token);
                    // this.$router.push("/home");
                } catch (error) {
                    _this.showError = true
                    _this.message = 'request failed';
                }
            }
        },
        mounted: function () {
            var _this = this;
            _this.login().then(function(){
                console.log('_this.$store.getters.StateUser', _this.$store.getters.StateUser);
                if(_this.$store.getters.StateUser == undefined)
                {
                    _this.showError = true
                    _this.message = 'invalid token';
                }else
                {
                    console.log('test', _this.$store.getters.StateUser.userAuthtoken);
                    _this.$axios.defaults.headers.common['Authorization'] = _this.$store.getters.StateUser.userAuthtoken;
                    location.href = '/home';
                }
                
            });
            
            
        }
    };
</script>
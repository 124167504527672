<template>

    <div class='home-new'>
        <BackButton title="BET1" />
        <Balance />
        <div class='submit-form'>
            <div class="d-flex align-items-center custom-datepicker" @click="pickDate = true" >
                <span >
                    {{ formatDate(betTime[0]) }}
                    <i class="fa-solid fa-chevron-right"></i>
                </span> 
            </div>
        
            <div class='sel-no-arrow' >
           
            <select v-model="selectedTypeIndex" @change="checkConfigType($event)">
                <option v-for="(type, type_index) of betConfig?.type" :key="type.type_id" :value="type_index" >{{ type.type_name }}</option>
            </select>
            </div>
        </div>
        <div class='logoPart' v-if="betConfig.length != 0" >
            <div v-for="legend of betConfig?.channels[0].legends" :key="legend.legend_id" >
                <img v-if="legend.legend_code == 'M'" class="product-dis-class" :class="isProoductSelected(legend.legend_code)? 'product-active':'' " @click="toggleSelectProduct(legend.legend_code)" src="@/assets/images/logo/products/magnum.png" />
                <img v-if="legend.legend_code == 'P'" class="product-dis-class" :class="isProoductSelected(legend.legend_code)? 'product-active':'' " @click="toggleSelectProduct(legend.legend_code)" src="@/assets/images/logo/products/damacai.png" />
                <img v-if="legend.legend_code == 'T'" class="product-dis-class" :class="isProoductSelected(legend.legend_code)? 'product-active':'' " @click="toggleSelectProduct(legend.legend_code)" src="@/assets/images/logo/products/sportstoto.png" />
                <img v-if="legend.legend_code == 'S'" class="product-dis-class" :class="isProoductSelected(legend.legend_code)? 'product-active':'' " @click="toggleSelectProduct(legend.legend_code)" src="@/assets/images/logo/products/sg4d.png" />
                <img v-if="legend.legend_code == 'B'" class="product-dis-class" :class="isProoductSelected(legend.legend_code)? 'product-active':'' " @click="toggleSelectProduct(legend.legend_code)" src="@/assets/images/logo/products/88.png" />
                <img v-if="legend.legend_code == 'K'" class="product-dis-class" :class="isProoductSelected(legend.legend_code)? 'product-active':'' " @click="toggleSelectProduct(legend.legend_code)" src="@/assets/images/logo/products/stc.png" />
                <img v-if="legend.legend_code == 'W'" class="product-dis-class" :class="isProoductSelected(legend.legend_code)? 'product-active':'' " @click="toggleSelectProduct(legend.legend_code)" src="@/assets/images/logo/products/cashsweep.png" />
                <img v-if="legend.legend_code == 'H'" class="product-dis-class" :class="isProoductSelected(legend.legend_code)? 'product-active':'' " @click="toggleSelectProduct(legend.legend_code)" src="@/assets/images/logo/products/gd.png" />
                <img v-if="legend.legend_code == 'L'" class="product-dis-class" :class="isProoductSelected(legend.legend_code)? 'product-active':'' " @click="toggleSelectProduct(legend.legend_code)" src="@/assets/images/logo/products/logo-nl9.png" />
                <p>{{ legend.legend_code }}</p>
            </div>
        </div>
        <div class='ticketBox'>
            <input placeholder="" ref="randomNumber" v-model="randomNumber" @click="changeFocus('randomNumber')" class='ant-input big-ticket-box focus-box' type="text" readOnly />
            <input placeholder="B" ref="bNumber" v-model="bNumber" @click="changeFocus('bNumber')" class="ant-input  focus-box" v-bind:class="{digitSpecialHide:!digitSpecialHide}" type="text" readOnly />
            <input placeholder="S" ref="sNumber" v-model="sNumber" @click="changeFocus('sNumber')" class="ant-input  focus-box" v-bind:class="{digitSpecialHide:!digitSpecialHide}" type="text" readOnly />
            <input placeholder="SA" ref="saNumber" v-model="saNumber" @click="changeFocus('saNumber')" class="ant-input  focus-box" type="text" readOnly />
            <input placeholder="SB" ref="sbNumber" v-model="sbNumber" @click="changeFocus('sbNumber')" class="ant-input  focus-box " v-bind:class="{digitSpecialHide:digitSpecialHide}" type="text" readOnly />
            <input placeholder="SC" ref="scNumber" v-model="scNumber" @click="changeFocus('scNumber')" class="ant-input  focus-box " v-bind:class="{digitSpecialHide:digitSpecialHide}" type="text" readOnly />
            <input placeholder="SD" ref="sdNumber" v-model="sdNumber" @click="changeFocus('sdNumber')" class="ant-input  focus-box " v-bind:class="{digitSpecialHide:digitSpecialHide}" type="text" readOnly />
            <input placeholder="SE" ref="seNumber" v-model="seNumber" @click="changeFocus('seNumber')" class="ant-input  focus-box " v-bind:class="{digitSpecialHide:digitSpecialHide}" type="text" readOnly />
            <input placeholder="SF" ref="sfNumber" v-model="sfNumber" @click="changeFocus('sfNumber')" class="ant-input  focus-box " v-bind:class="{digitSpecialHide:digitSpecialHide}" type="text" readOnly />
        </div>
        <div class='order-list-con' >
            <div class='order-list-header' >
                <div class='product-pat' ></div>
                <div class='code-num' ></div>
                <div class='method-col' ></div>
                <div class='b-col' v-bind:class="{digitSpecialHideResult:!digitSpecialHideResult}">B</div>
                <div class='s-col' v-bind:class="{digitSpecialHideResult:!digitSpecialHideResult}">S</div>
                <div class='sa-col' >SA</div>
                <div class='sb-col ' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">SB</div>
                <div class='sc-col ' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">SC</div>
                <div class='sd-col ' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">SD</div>
                <div class='se-col ' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">SE</div>
                <div class='sf-col ' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">SF</div>

                <div></div>
            </div>
            <div></div>
            <div class="displayInstruct" v-bind:class="{hideInstruction:hideInstruction}">
                <p>{{ $t('instructions.add_bet')}}</p>   
            </div>
            <div class='order-list-display' v-bind:class="{digitSpecialAdded:digitSpecialAdded}" v-for="(value, index) of orderList" :key="index" >
                <div class='product-pat' ><span class='product-num-code' >{{value.legend.join("")}}</span></div>
                <div class='code-num' >{{value.number}}</div>
                <div class='method-col' ><span :class='value.method==1? "":"display-method-label"' >{{ showMethodLabel(value.method) }}</span></div>
                <div class='b-col' v-bind:class="{digitSpecialHideResult:!digitSpecialHideResult}">{{value.b}}</div>
                <div class='s-col' v-bind:class="{digitSpecialHideResult:!digitSpecialHideResult}">{{value.s}}</div>
                <div class='sa-col' >{{value.sa}}</div>
                <div class='sb-col' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">{{value.sb}}</div>
                <div class='sc-col' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">{{value.sc}}</div>
                <div class='sd-col' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">{{value.sd}}</div>
                <div class='se-col' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">{{value.se}}</div>
                <div class='sf-col' v-bind:class="{digitSpecialHideResult:digitSpecialHideResult}">{{value.sf}}</div>
                <div><button class='btn-remove-input' @click="removeTicket(index,value.type_name)" ><i class="fa-solid fa-circle-xmark"></i></button></div>
            </div>
 
        </div>
    </div>
    <div class="date-box-con" v-if="pickDate" >
        <div class='date-box-header' >
            <button class='btn-close-date-box' @click="validateDateLength" ><i class="fa-solid fa-xmark"></i></button>
            <h3>Draw Date</h3>
            <span>*Max pick 3 days</span>
        </div>
        <div class='date-box-list' >
        <div class="form-check" v-for="date_data of dateList" :key="date_data" >
            <label >
                <input type="checkbox" :value="date_data.date" v-model="betTime" />
                {{date_data.label}}
            </label>
        </div>
        <button class='btn-date-box' @click="validateDateLength" >OK</button>
        </div>
    </div>

<!-- <div class="bet-keypad" >
        <table>
            <tbody>
                <tr class="keypad-tr-small four-keys" >
                    <td>
                        <button class='btn-method' :class="methodType==2? 'btn-active':''" @click="changeMethod(2)" >{{ $t('keypad.box')}}</button>
                    </td>
                    <td>
                        <button class='btn-method' :class="methodType==3? 'btn-active':''" @click="changeMethod(3)" >{{ $t('keypad.i_box')}}</button>
                    </td>
                    <td>
                        <button class='btn-method' :class="methodType==4? 'btn-active':''" @click="changeMethod(4)" >{{ $t('keypad.reverse')}}</button>
                    </td>
                    <td>
                        <span class='display-method-label' >{{ overAllTootal }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
        <table>
            <tbody>
                <tr class="keypad-tr-big four-keys" >
                        <td>
                            <button @click="changeText(1)" >1</button>
                        </td>
                        <td>
                            <button @click="changeText(2)" >2</button>
                        </td>
                        <td>
                            <button @click="changeText(3)" >3</button>
                        </td>
                        <td>
                            <button @click="changeText('delete')" ><i class="fa-solid fa-delete-left"></i></button>
                        </td>
                    </tr>
            </tbody>
        </table>
        <table>
            <tbody>
                <tr class="keypad-tr-big four-keys" >
                        <td>
                            <button @click="changeText(4)" >4</button>
                        </td>
                        <td>
                            <button @click="changeText(5)" >5</button>
                        </td>
                        <td>
                            <button @click="changeText(6)" >6</button>
                        </td>
                        <td>
                            <button @click="rollNumber">{{$t('keypad.roll')}}</button>
                        </td>
                    </tr>
            </tbody>
        </table>
        <table>
            <tbody>
                <tr class="keypad-tr-big four-keys" >
                        <td>
                            <button @click="changeText(7)" >7</button>
                        </td>
                        <td>
                            <button @click="changeText(8)" >8</button>
                        </td>
                        <td>
                            <button @click="changeText(9)" >9</button>
                        </td>
                        <td>
                            <button @click="addTicket" ><i class="fa-solid fa-circle-plus"></i></button>
                        </td>
                    </tr>
            </tbody>
        </table>
        <table>
            <tbody>
                <tr class="keypad-tr-big five-keys" >
                        <td>
                        <button @click="changeFocusArrow('left')" ><i class="fa-solid fa-arrow-left"></i></button>
                        </td>
                        <td>
                            <button @click="changeText(0)" >0</button>
                        </td>
                         <td>
                            <button @click="changeText(1)" >.</button>
                        </td>
                        <td>
                        <button @click="changeFocusArrow('right')" ><i class="fa-solid fa-arrow-right"></i></button>
                        </td>
                        <td>
                            <button class="btn-keypad-bet" @click="showBetPreviewModal = true" >{{$t('keypad.bet')}}</button>
                        </td>
                    </tr>
            </tbody>
        </table>
</div> -->

    <div :class="showKeypad? 'bet-keypad-con':'hide-keypad'" >
      <div class="bet-keypad" >
            <table>
                <tbody>
                    <tr class="keypad-tr-small four-keys" >
                        <td>
                            <button class='btn-method' :class="methodType==2? 'btn-active':''" @click="changeMethod(2)" >{{ $t('keypad.box')}}</button>
                        </td>
                        <td>
                            <button class='btn-method' :class="methodType==3? 'btn-active':''" @click="changeMethod(3)" >{{ $t('keypad.i_box')}}</button>
                        </td>
                        <td>
                            <button class='btn-method' :class="methodType==4? 'btn-active':''" @click="changeMethod(4)" >{{ $t('keypad.reverse')}}</button>
                        </td>
                        <td>
                            <span class='display-method-label' >{{ overAllTootal }}</span>
                        </td>
                    </tr>
                    
                   
                  
                   
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr class="keypad-tr-big four-keys" >
                        <td>
                            <button @click="changeText(1)" >1</button>
                        </td>
                        <td>
                            <button @click="changeText(2)" >2</button>
                        </td>
                        <td>
                            <button @click="changeText(3)" >3</button>
                        </td>
                        <td style="width: 36%;">
                            <button @click="changeText('delete')" ><i class="fa-solid fa-delete-left"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr class="keypad-tr-big four-keys" >
                        <td>
                            <button @click="changeText(4)" >4</button>
                        </td>
                        <td>
                            <button @click="changeText(5)" >5</button>
                        </td>
                        <td>
                            <button @click="changeText(6)" >6</button>
                        </td>
                        <td style="width: 36%;">
                            <button @click="rollNumber">{{$t('keypad.roll')}}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr class="keypad-tr-big four-keys" >
                        <td>
                            <button @click="changeText(7)" >7</button>
                        </td>
                        <td>
                            <button @click="changeText(8)" >8</button>
                        </td>
                        <td>
                            <button @click="changeText(9)" >9</button>
                        </td>
                        <td>
                            <button @click="addTicket" ><i class="fa-solid fa-circle-plus"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <tbody>
                    <tr class="keypad-tr-big five-keys" >
                        <td>
                        <button @click="changeFocusArrow('left')" ><i class="fa-solid fa-arrow-left"></i></button>
                        </td>
                        <td>
                            <button @click="changeText(0)" >0</button>
                        </td>
                         <td>
                            <button @click="changeText('.')" >.</button>
                        </td>
                        <td>
                        <button @click="changeFocusArrow('right')" ><i class="fa-solid fa-arrow-right"></i></button>
                        </td>
                        <td>
                            <button class="btn-keypad-bet" @click="showBetPreviewModal = true" >{{$t('keypad.bet')}}</button>
                        </td>
                    </tr>
                </tbody>
            </table>
      </div>
    </div>
     <vue-final-modal v-model="showBetPreviewModal">
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                    </div>
                    <div class="modal-body">
                        <div className='bet-preview-con'>
                            <ul>
                                <template v-for="date of betTime" :key="date" >
                                    <li>{{ formatDatePreview(date) }}</li>
                                    <template v-if="betPreviewData != false && betPreviewData?.validTicketOrder.length != 0" >
                                        <template v-for="valid_ticket of betPreviewData?.validTicketOrder" :key="valid_ticket" >
                                            <template v-if="valid_ticket.date == date" >
                                                <li>
                                                    <template v-for="legend of valid_ticket.legends" :key="legend" >
                                                        <img v-if="legend == 'M'" class="preview-log-img" src="@/assets/images/logo/products/magnum.png" />
                                                        <img v-if="legend == 'P'" class="preview-log-img" src="@/assets/images/logo/products/damacai.png" />
                                                        <img v-if="legend == 'T'" class="preview-log-img" src="@/assets/images/logo/products/sportstoto.png" />
                                                        <img v-if="legend == 'S'" class="preview-log-img" src="@/assets/images/logo/products/sg4d.png" />
                                                        <img v-if="legend == 'B'" class="preview-log-img" src="@/assets/images/logo/products/88.png" />
                                                        <img v-if="legend == 'K'" class="preview-log-img" src="@/assets/images/logo/products/stc.png" />
                                                        <img v-if="legend == 'W'" class="preview-log-img" src="@/assets/images/logo/products/cashsweep.png" />
                                                        <img v-if="legend == 'H'" class="preview-log-img" src="@/assets/images/logo/products/gd.png" />
                                                        <img v-if="legend == 'L'" class="preview-log-img" src="@/assets/images/logo/products/logo-nl9.png" />
                                                    </template>
                                                </li>
                                                <li>
                                                    {{ valid_ticket.number }}  = {{ valid_ticket.b==0? "":"B"+valid_ticket.b }} {{ valid_ticket.s==0? "":"S"+valid_ticket.s }} {{ valid_ticket.sa==0? "":"SA"+valid_ticket.sa }} {{ valid_ticket.sb==0? "":"SB"+valid_ticket.sb }} {{ valid_ticket.sc==0? "":"SC"+valid_ticket.sc }} {{ valid_ticket.sd==0? "":"SD"+valid_ticket.sd }} {{ valid_ticket.se==0? "":"SE"+valid_ticket.se }} {{ valid_ticket.sf==0? "":"SF"+valid_ticket.sf }}
                                                </li>   
                                                <li>
                                                    <p class='bet-preview-total' >GT: {{ valid_ticket.totalPrice }}</p>
                                                </li> 
                                            </template>    
                                        </template>    
                                    </template>
                                    <template v-for="invalid_legend of invalidLegend(date)" :key="invalid_legend" >
                                        <li>{{ formatDatePreview(date) }} #{{ invalid_legend }} (SO)</li>
                                    </template>
                                    <li><hr /></li>

                                </template>
                                <li v-if="betPreviewData != false && betPreviewData?.totalPrice != 0" >
                                    <p class='bet-preview-total' >FT: {{ betPreviewData?.totalPrice }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary custom-primary-button" @click="confirmBet" >Ok</button>
                        <button type="button" class="btn btn-secondary custom-secondary-button" @click="showBetPreviewModal = false" >cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
<div v-if="loadingSubmit" class="loadingSubmit">
    <div class="loader"></div>
<p>Loading Please Wait</p>
</div>
</template>
<style type="text/css">
.displayInstruct p {
    text-align: center;
    margin-top: 25%;
    font-weight: bold;
    font-size: 15px;
}.hideInstruction{
    display:none;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #EDA526;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loadingSubmit {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #2a2b2ec2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1000;
}
.loadingSubmit  p {
    text-align: center;
    color: #fff;
    /* margin-left: 42px; */
    margin-top: 12px;
}

</style>
<script>
  import BackButton from '@/components/headers/BackButton.vue'
  import Balance from '@/components/headers/Balance.vue'
  import moment from 'moment'

  export default {
      name: "One",
      components: {BackButton, Balance},
      data() {
        return {
            betTime:[moment(new Date()).format('YYYY-MM-DD')],
            selectedTypeIndex:1,
            betConfig:[],
            selectedProducts:[],
            dateList:[],
            randomNumber:"",
            legend:"",
            product_code:"",
            type_name:"",
            bNumber:"",
            sNumber:"",
            saNumber:"",
            sbNumber:"",
            scNumber:"",
            sdNumber:"",
            seNumber:"",
            sfNumber:"",
            methodType:1,
            fieldFocus:"",
            pickDate:false,
            showKeypad:false,
            fieldListFocus:[
                'randomNumber',
                'bNumber',
                'sNumber',
                'saNumber',
                'sbNumber',
                'scNumber',
                'sdNumber',
                'seNumber',
                'sfNumber',
            ],
            orderList: [],
            overAllTootal:0,
            showBetPreviewModal:false,
            betPreviewData:false,
            digitSpecial : 
                 {
                "type_id" : "2",
                "type_name" : "4D Special",
                "method_available_id": [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6"
                ],
                "type_number_required": "4"
            },
            digitSpecialHide : true,
            // digitSpecialHideResult: false,
            digitSpecialHideResult: true,
            digitSpecialAdded : false,
            hideInstruction: false,
            count4DS: 0,
            loadingSubmit:false,
            dot_count:0,
            
        }
      },
      methods: {
        async getConfig () {
          let _this = this;
          await this.$axios.post('/web/ticket/getBetConfig',{}).then(function(response){

            _this.betConfig = response.data.return;
            _this.add4Dspecial();
         
          })
 
        },

        add4Dspecial(){
           this.betConfig.type.push(this.digitSpecial);             
        },
        checkConfigType(event){
            
        if(event.target.value == "4"){
                console.log("special");
                this.digitSpecialHide = false;       
                this.digitSpecialHideResult = false; 
                if(this.count4DS > 0){
                    this.digitSpecialHideResult = false; 
                }
            }else{
                this.digitSpecialHide = true;
                this.digitSpecialHideResult = true; 
                if(this.count4DS > 0){
                    this.digitSpecialHideResult = false; 
                }             
            }
           
        
        },
        isProoductSelected(legend_code) {
            var length = this.selectedProducts.length;
            for(var i = 0; i < length; i++) {
                if(this.selectedProducts[i] == legend_code) return true;
            }
            return false;
        },
        toggleSelectProduct (legend_code) {
            let index = this.selectedProducts.indexOf(legend_code);
            if(index > -1)
            {
                this.selectedProducts.splice(index,1);
            }else
            {
                this.selectedProducts.push(legend_code);
            }
            // this.selectedProducts.push(legend_code);
            
        },
        changeMethod (method_number) {
            if(this.methodType == method_number)
            {
                this.methodType = 1;
            }else
            {
                this.methodType = method_number;
            }
            this.$refs[this.fieldFocus].focus();
        },
        changeFocus (field_focus) {
            this.fieldFocus = field_focus;
            this.$refs[field_focus].focus();
            this.showKeypad = true;
        },
        changeText (text_operation) {
            let type_number_required = parseInt(this.betConfig.type[this.selectedTypeIndex].type_number_required);
            if(text_operation == "delete")
            {
                console.log(text_operation);
                var current_text = this[this.fieldFocus];
                current_text = current_text.substr(0, current_text.length - 1);
                this[this.fieldFocus] = current_text;
            }
            // else if(text_operation == "."){

            //     this.dot_count++;
            //     if(this.dot_count <= 1){
            //         this[this.fieldFocus] = this[this.fieldFocus] + text_operation;
            //     }else{
                
            //         if(this[this.fieldFocus].includes(".")){
            //             console.log('has');
            //            current_text = this[this.fieldFocus];
            //             current_text = current_text.substr(0, current_text.length - 1);
            //             this[this.fieldFocus] = current_text;
            //             this.dot_count = 0;
            //         }else{
            //             this[this.fieldFocus] = this[this.fieldFocus];
            //         }
                  
            //     }
            //     console.log(this.dot_count);
                
                // console.log('dot',text_operation);
                // let temp_txt = this[this.fieldFocus] + text_operation;
                // console.log('dottemp',temp_txt);
                // let count_dot = 0;
                // if(temp_txt.includes(".")){
                //     count_dot++;
                //     if(count_dot < 1){
                //         temp_txt = this[this.fieldFocus];
                //     }
                // }
                // console.log(count_dot);
                //     let temp_text = temp_txt.replace(/\./g, "");
                //     console.log('has dots');
                //     console.log('rep',temp_text);
                    
                // }
                // let test_string ="hello.word";
                // let new_test_string = test_string.replace(/\./g, "");
                // console.log('new_test',new_test_string);
                // this[this.fieldFocus] = temp_txt;
            // }
            else
            {
                console.log('type_number_required', type_number_required);
                console.log('fieldFocus', this.fieldFocus);
                let temp_txt = this[this.fieldFocus] + text_operation;

                if(this.fieldFocus == 'randomNumber')
                {
                    if(temp_txt.length <= type_number_required)
                    {
                        this[this.fieldFocus] = this[this.fieldFocus] + text_operation;
                    }
                }else
                {
                    // if(text_operation == "."){
                    //     if(temp_txt.length >= 4)
                    //     {
                    //         this[this.fieldFocus] = this[this.fieldFocus] + text_operation;
                    //     }
                       
                    // }
                    console.log('temp_text',temp_txt.length);
                    if(temp_txt.length <= 4)
                    {
                        console.log('add');
                        this[this.fieldFocus] = this[this.fieldFocus] + text_operation;
                    }else{
                        if(this[this.fieldFocus].includes(".")){
                            this[this.fieldFocus] = this[this.fieldFocus] + text_operation;
                        }
                        
                    }
                }
                
                
            }

            this.$refs[this.fieldFocus].focus();
        },
        changeFocusArrow (arrow) {
            let current_index = this.fieldListFocus.indexOf(this.fieldFocus);
            if(arrow == "left")
            {
                current_index -= 1;
                
                current_index = current_index>=0? current_index:0; 
                
            }
            if(arrow == 'right')
            {
                current_index += 1;
                current_index = current_index<this.fieldListFocus.length? current_index:0; 
                if(this.$refs[this.fieldListFocus[current_index]].classList.contains('digitSpecialHide')){
                    current_index += 2
                }
            }
            
            this.changeFocus(this.fieldListFocus[current_index]);            

        },
        rollNumber  () {
            let type_number_required = parseInt(this.betConfig.type[this.selectedTypeIndex].type_number_required);

            let min = "1";
            let max = "9";

            for (let index = 0; index < (type_number_required - 1); index++) {

            min +="0";
            max +="9";
            
            }

            min = parseInt(min);
            max = parseInt(max);

            var rundom_ticket = Math.floor(min + Math.random() * max);

            this.randomNumber = ""+rundom_ticket;
            this.changeFocus("randomNumber");
        },
        addTicket () {
            
            let type_id = this.betConfig.type[this.selectedTypeIndex].type_id;
            let type_name = this.betConfig.type[this.selectedTypeIndex].type_name;
            let type_number_required = parseInt(this.betConfig.type[this.selectedTypeIndex].type_number_required);
            console.log("this.randomNumber.length",this.randomNumber);
            if(this.selectedProducts.length == 0 || this.randomNumber == "" || this.randomNumber.length != type_number_required)
            {
                this.$toast.error(`Add product or check ticket number length<br/> ( ${this.betConfig.type[this.selectedTypeIndex].type_name} allow ${this.betConfig.type[this.selectedTypeIndex].type_number_required} digit only)`,{
                    position:"top"
                });

                return;
            }
            
            var b_input = this.bNumber==""? 0:parseFloat(this.bNumber);
            console.log(this.bNumber);
            var s_input = this.sNumber==""? 0:parseFloat(this.sNumber);
            var sa_input = this.saNumber==""? 0:parseFloat(this.saNumber);
            var sb_input = this.sbNumber==""? 0:parseFloat(this.sbNumber);
            var sc_input = this.scNumber==""? 0:parseFloat(this.scNumber);
            var sd_input = this.sdNumber==""? 0:parseFloat(this.sdNumber);
            var se_input = this.seNumber==""? 0:parseFloat(this.seNumber);
            var sf_input = this.sfNumber==""? 0:parseFloat(this.sfNumber);

            if(b_input == "" && s_input == "" && sa_input == "" &&  sb_input == "" && sc_input == "" && sd_input == "" && se_input == "" && sf_input == "")
            {
                this.$toast.error(`Please add at least 1 of the ticket options`,{
                    position:"top"
                });

                return;
            }

            var total_bet = b_input + s_input + sa_input + sb_input + sc_input + sd_input + se_input  + sf_input;
            

            total_bet = total_bet * this.selectedProducts.length;

            if(this.methodType == 2)
            {
                var random_factorial = 1;
                for (let index = type_number_required; index > 0; index--) {
                    
                    random_factorial = random_factorial * index; 
                }

                total_bet = total_bet * random_factorial;
            }

            
            if(this.methodType == 4)
            {
            total_bet = total_bet * 2;
            }

            let selected_product = this.selectedProducts;
            let selected_method = this.methodType;
            var ticket_data = {
            "type": type_id,
            "number": this.randomNumber,
            "legend": selected_product,
            "type_name":type_name,
            "b": b_input,
            "s": s_input,
            "sa": sa_input,
            "sb":sb_input,
            "sc":sc_input,
            "sd":sd_input,
            "se":se_input,
            "sf":sf_input,
            "method": selected_method,
            "total_bet":total_bet
            };

            if(this.selectedTypeIndex == "4"){
                this.count4DS ++;
                
                if(this.count4DS > 0){    
                    this.digitSpecialHideResult = false;
                }

            }
            this.orderList.push(ticket_data);
            this.legend="";
            this.bNumber="";
            this.sNumber="";
            this.saNumber="";
            this.sbNumber="";
            this.scNumber="";
            this.sdNumber="";
            this.seNumber="";
            this.sfNumber="";
            this.selectedProducts = [];

            this.randomNumber="";
            this.hideInstruction = true;
            this.dot_count = 0;
            this.ticketOrderPreview();
            
        },
        showMethodLabel (method_id) {
            if(method_id == 2)
            {
                return "B";
            }

            if(method_id == 3)
            {
                return "IB";
            }

            if(method_id == 4)
            {
                return "RV";
            }

            return "";
            
        },
        removeTicket (ticket_index,type_name) {
            this.orderList.splice(ticket_index, 1);   
            if(type_name == "4D Special"){
                this.count4DS --;
                if(this.count4DS <= 0){
                    this.digitSpecialHideResult = true;
                }
            }
            this.ticketOrderPreview();
        },
        async ticketOrderPreview () {
            let _this = this;
            await this.$axios.post('/web/ticket/betPreview',{
                data:{
                    "channelId":1,
                    "dates":this.betTime,
                    "tickets":this.orderList
                }
            }).then(function(response){
                console.log('_this.betPreviewData', _this.betPreviewData);
                console.log('response.data.return', response.data.return);
                _this.betPreviewData = response.data.return;
                _this.overAllTootal = response.data.return.totalPrice;
            }).catch(function(resoponse) {
                console.log('error preview', resoponse);
                _this.betPreviewData = false;
                _this.overAllTootal = "0.00";
            });
        },
        formatDate (date_time) {
            return moment(date_time).format('DD/MM');
        },
        formatDatePreview (date_time) {
            var date_split = date_time.split("-");
            return '{'+date_split[1]+''+date_split[2]+'}';
        },
        invalidLegend (date_item) {
            let invalid_legend = [];
            let user_selected = [];
            let valid_legend = [];

            this.orderList.forEach(ticket_data => {
                ticket_data.legend.forEach(legend_text => {
                    var index_sel = user_selected.indexOf(legend_text);

                    if(index_sel !== -1)  
                    { 
                        // order_date.splice(index_sel, 1);
                    }else
                    {
                        user_selected.push(legend_text);
                    }
                });
            });
            
            if(this.betPreviewData != false)
            {
                this.betPreviewData.validTicketOrder.forEach(valid_ticket => {
                    if(valid_ticket.date == date_item)
                    {
                        valid_ticket.legends.forEach(legend => {
                            var index_sel = valid_legend.indexOf(legend);

                            if(index_sel !== -1)  
                            { 
                            // order_date.splice(index_sel, 1);
                            }else
                            {
                                valid_legend.push(legend);
                            }
                        });
                    }
                });
            }

            invalid_legend = user_selected.filter(function(obj) { return valid_legend.indexOf(obj) == -1; });

            return invalid_legend;

        },
        async confirmBet () {

            let _this = this;
            _this.loadingSubmit=true;
            console.log('orderlist',this.orderList);
            await this.$axios.post('/web/ticket/betConfirm',{
                data:{
                    "channelId":1,
                    "dates":this.betTime,
                    "tickets":this.orderList
                }
            }).then(function(response){
                _this.loadingSubmit = false;
                _this.showBetPreviewModal = false;
                _this.$toast.success(response.data.msg,{
                    position:"top"
                });

                _this.betPreviewData = false;
                _this.overAllTootal = 0;
                _this.orderList = [];
                _this.selectedProducts = [];
                _this.bNumber="";
                _this.sNumber="";
                _this.saNumber="";
                _this.sbNumber="";
                _this.scNumber="";
                _this.sdNumber="";
                _this.randomNumber="";


            });
        },
        validateDateLength() {
            if(this.betTime.length <= 3)
            {
                this.pickDate =  false;
            }else
            {
                this.$toast.error("Date exceed to max pick size (3 days)",{
                    position:"top"
                });
            }
        }
      },
      mounted: function () {
        this.getConfig();
        // this.add4Dspecial();
        //set date list
        var today = new Date();
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        console.log('order',this.orderList);
        for (let index = 0; index < 7; index++) {
            var calc_date = new Date(today);

            calc_date.setDate(calc_date.getDate() + index);

            this.dateList.push({
                'date': moment(calc_date).format('YYYY-MM-DD'),
                'label': moment(calc_date).format('DD/MM')+" "+days[calc_date.getDay()]
            });

        }

      }
  };
</script>
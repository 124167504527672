<template>
    <div v-if="showError">Please login again</div> 
    <p v-else>Loading...</p> 
</template>
<script>
    import {mapActions} from "vuex";
    export default {
        name: "Login",
        components: {},
        data() {
            return {
                form: {
                    username: "",
                    password: "",
                },
                showError: false
            };
        },
        methods: {
            ...mapActions(["generateToken","LogIn"]),
            async submit() {
                try {
                    var auth_token = 'dGVzdGtleXx0ZXN0c2VjcmV0';
                    await this.generateToken(auth_token);
                    // this.$router.push("/home");
                } catch (error) {
                    this.showError = true
                }
            },
            async login() {
                try {
                    var user = {
                        username:'skytest',
                        login_token: this.$store.getters.StateLoginToken
                    };
                    await this.LogIn(user);
                    // this.$router.push("/home");
                } catch (error) {
                    this.showError = true
                }
            }
        },
        mounted: function () {
            var _this = this;
            this.submit().then(function(){
                _this.login().then(function(){
                    _this.$axios.defaults.headers.common['Authorization'] = _this.$store.getters.StateUser.userAuthtoken;
                    console.log(_this.$store.getters.StateUser.userAuthtoken);
                    _this.$router.push("/home");
                });
            });
            
        }
    };
</script>
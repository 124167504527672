<template>
    <div class="header">
        <button class="back" @click="title=='Home'? false:$router.go(-1)" ><i class="fa-solid fa-chevron-left"></i></button>
        <div class="header-titles">
          <span>{{ title }}</span>
          <span class="btn-lang"><a class="" @click="showMultilingualModal = true" >Language</a></span>
        </div>
       
    </div>

    <vue-final-modal v-model="showMultilingualModal">
        <div class="modal multilingual-container" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t('modals.select_language') }}</h5>
                    </div>
                    <div class="modal-body">
                         <!-- <select v-model="$i18n.locale">
                            <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
                            {{ i }}
                            </option>   
                        </select> -->
                        <div class="multilingual-select">
                             <div v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
                                <span>{{ i }}</span><input type="radio" v-model="$i18n.locale" name="multilingual" :value="locale"  @click="showMultilingualModal = false" @change="updateLang($event)" >
                        </div>
                        </div>
                       

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary custom-secondary-button" @click="showMultilingualModal = false" >cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </vue-final-modal>
</template>
<script>

export default {
  name: 'BackButton',
   data() {
        return {
            locales: { "English": "en",
                        "中文": "cn",
                       "Bahasa Melayu": "ms",
                       "แบบไทย": "th"
                },
            showMultilingualModal:false,
        }
      },
    methods: {
        updateLang(event){   
            localStorage.setItem("language",event.target.value);        
        }
    },  
    mounted: function () {
        localStorage.setItem("language", 'en');
       
    },
  props: {
    msg: String,
    title: String,
  }
}
</script>
<template>
    <div class='home-new'>
        <BackButton title="28WIN" />
        <Balance />
        <div class='submit-form method-two'>
            <div class='sel-no-arrow sel-id' >
                <select >
                    <option value="MYR" selected >KSJDOWE)#$KLDLsdlslDKLSD</option>
                </select>
            </div>
        
            <div class='sel-no-arrow' >
                <select >
                    <option value="MYR" selected >MYR</option>
                </select>
            </div>
            <div class='sel-no-arrow' >
                <button type="button" class="btn btn-primary custom-primary-button" >{{ $t('button.submit')}}</button>
            </div>
        </div>
        <div class="bet-2-entry-con" >
            <ul class="entry-2-list" >
                <li v-for="entry of entryList" :key="entry" >
                    {{ entry }}
                </li>
            </ul>
            <input type="text" class="entry-input" v-model="entryInput" readonly  />
        </div>
        <div class="bet-keypad-con" >
            <div class="bet-keypad" >
                <table style="table-layout:fixed;" >
                    <tbody>
                        <tr class="keypad-tr-big" >
                            <td>
                                <button @click="changeText(1)"  >1</button>
                            </td>
                            <td>
                                <button @click="changeText(2)"  >2</button>
                            </td>
                            <td>
                                <button  @click="changeText(3)"  >3</button>
                            </td>
                            <td>
                                <button @click="changeText('delete')"  >D</button>
                            </td>
                        </tr>
                        <tr class="keypad-tr-big" >
                            <td>
                                <button  @click="changeText(4)"  >4</button>
                            </td>
                            <td>
                                <button  @click="changeText(5)"  >5</button>
                            </td>
                            <td>
                                <button  @click="changeText(6)"  >6</button>
                            </td>
                            <td>
                                <button  @click="changeText('clear')"  >C</button>
                            </td>
                        </tr>
                        <tr class="keypad-tr-big" >
                            <td>
                                <button  @click="changeText(7)"  >7</button>
                            </td>
                            <td>
                                <button  @click="changeText(8)"  >8</button>
                            </td>
                            <td>
                                <button  @click="changeText(9)"  >9</button>
                            </td>
                            <td>
                                <button><i class="fa-solid fa-circle-plus"></i></button>
                            </td>
                        </tr>
                        <tr class="keypad-tr-big" >
                            <td>
                            <button  @click="changeText('*')"  >*</button>
                            </td>
                            <td>
                                <button  @click="changeText(0)"  >0</button>
                            </td>
                            <td>
                            <button  @click="changeText('#')"  >#</button>
                            </td>
                            <td>
                                <button @click="addEntry" >ENTER</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
  import BackButton from '@/components/headers/BackButton.vue'
  import Balance from '@/components/headers/Balance.vue'

  export default {
      name: "Two",
      components: {BackButton, Balance},
      data() {
        return {
            entryInput:"",
            entryList:[]
        }
      },
      methods: {
        changeText (text_operation) {
            if(text_operation == "delete")
            {
                console.log(text_operation);
                var current_text = this.entryInput;
                current_text = current_text.substr(0, current_text.length - 1);
                this.entryInput = current_text;
            }
            else if(text_operation == "clear")
            {
                this.entryInput = "";
            }
            else
            {
                this.entryInput = this.entryInput + text_operation;
            }

        },
        addEntry () {
            if(this.entryInput != "")
            {
                this.entryList.push(this.entryInput);
                this.entryInput = "";
            }
        }
      },
      mounted: function () {
        
      }
  };
</script>
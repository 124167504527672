export default {
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])}
  },
  "errors": {
    "no_orders_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No orders found"])},
    "no_records_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records found"])}
  },
  "keypad": {
    "box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOX"])},
    "i_box": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I-BOX"])},
    "reverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVERSE"])},
    "roll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROLL"])},
    "bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BET"])}
  },
  "button": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])}
  },
  "modals": {
    "select_language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Language"])}
  },
  "home": {
    "warm_reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warm reminder: The wining money will be deposited"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BALANCE"])},
    "bet_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BET 1"])},
    "bet_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BET 2"])},
    "receipts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECEIPTS"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RESULTS"])},
    "strike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STRIKE"])},
    "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAYOUT"])}
  },
  "receipt": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])}
  },
  "results": {
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "starters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STARTERS"])},
    "consolation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSOLATION"])}
  },
  "strike": {
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEARCH"])}
  },
  "instructions": {
    "add_bet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click ✚ button to add the number to the list"])}
  }
}
<template>
  <div class='home-new' v-bind:style="{backgroundImage: `url(`+require('@/assets/images/profilebg.png')+`)` }" >
      <BackButton title="Home" />
      <div class='home-new-innner'>
        <div class='marquee scrolling-text'>
          <p>{{ $t('home.warm_reminder')}}</p>
        </div>
        <div class='top-part'>
          <div class='left-text-part'>
            <h3>{{ $t('home.balance') }}</h3>
            <h4>MYR <strong>{{ userBalance }}</strong></h4>
          </div>
          
        </div>
        
        <div class='block-wrapper'>
          <div class='betbox' >
            <img src="@/assets/images/icon1.png" alt="" @click="$router.push('/bet-one')" />
            <span>{{ $t('home.bet_1') }}</span>
          </div>
          <div class='betbox disabled-text'>
            <img src="@/assets/images/icon2.png" alt="" @click="$router.push('/bet-two')" />
            <span>{{ $t('home.bet_2') }}</span>
          </div>
          <div class='betbox' @click="$router.push('/group-orders/'+today+'/'+today)" >
            <img src="@/assets/images/icon3.png" alt="" />
            <span>{{ $t('home.receipts') }}</span>
          </div>
          <div class='betbox' @click="$router.push('/results')" >
            <img src="@/assets/images/icon4.png" alt="" />
            <span>{{ $t('home.results') }}</span>
          </div>
          <div class='betbox' @click="$router.push('/strike')" >
            <img src="@/assets/images/icon5.png" alt="" />
            <span>{{ $t('home.strike') }}</span>
          </div>
          <div class='betbox' @click="$router.push('/payout')" >
            <img src="@/assets/images/icon6.png" alt="" />
            <span>{{ $t('home.payout') }}</span>
          </div>
        </div>
        <div class='playout-con' >
            <!-- <div class='playout-table-con'>
                <table>
                    <tr>
                        <th colspan="6" class="table-header"><span>4D</span><span>B</span><span>S</span><span>SA</span></th>
                        <th colspan="5" class="table-header"><span>GD</span><span>B</span><span>S</span><span>SA</span></th>
                    </tr>
                    <tr class='tr-first'>
                        <td>1st</td>
                        <td></td>
                        <td>3500</td>
                        <td>5000</td>
                        <td></td>
                        <td>8000</td>
                        <td></td>
                        <td>2500</td>
                        <td>3500</td>
                        <td></td>
                        <td>6000</td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td></td>
                        <td>1200</td>
                        <td>2400</td>
                        <td>4B</td>
                        <td>8000</td>
                        <td></td>
                        <td>1000</td>
                        <td>2000</td>
                        <td>4B</td>
                        <td>6000</td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td></td>
                        <td>600</td>
                        <td>1200</td>
                        <td>4C</td>
                        <td>8000</td>
                        <td></td>
                        <td>500</td>
                        <td>1000</td>
                        <td>4C</td>
                        <td>6000</td>
                    </tr>
                    <tr>
                        <td>SPC</td>
                        <td></td>
                        <td>250</td>
                        <td></td>
                        <td>4D</td>
                        <td>800</td>
                        <td></td>
                        <td>200</td>
                        <td></td>
                        <td>4D</td>
                        <td>600</td>
                    </tr>
                    <tr>
                        <td>CON</td>
                        <td></td>
                        <td>80</td>
                        <td></td>
                        <td>4E</td>
                        <td>800</td>
                        <td></td>
                        <td>60</td>
                        <td></td>
                        <td>4E</td>
                        <td>600</td>
                    </tr>
                </table>
            </div>
            <div class='playout-table-con'>
                <table>
                    <tr>
                        <th colspan="6" class="table-header"><span>3D</span><span>F</span></th>
                        <th colspan="5" class="table-header"><span>3D</span><span>F</span></th>
                    </tr>
                    <tr class='tr-first'>
                        <td>1st</td>
                        <td>3A</td>
                        <td>840</td>
                        <td>280</td>
                        <td></td>
                        <td></td>
                        <td>3A</td>
                        <td>660</td>
                        <td>250</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td>3B</td>
                        <td>840</td>
                        <td>280</td>
                        <td></td>
                        <td></td>
                        <td>3B</td>
                        <td>660</td>
                        <td>250</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td>3C</td>
                        <td>840</td>
                        <td>280</td>
                        <td></td>
                        <td></td>
                        <td>3C</td>
                        <td>660</td>
                        <td>150</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>SPC</td>
                        <td>3D</td>
                        <td>84</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>3D</td>
                        <td>66</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CON</td>
                        <td>3E</td>
                        <td>84</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>3E</td>
                        <td>66</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class='playout-table-con'>
            <table>
                    <tr>
                        <th colspan="6" class="table-header"><span>2D</span><span>F</span></th>
                        <th colspan="5" class="table-header"><span>2D</span><span>F</span></th>
                    </tr>
                    <tr class='tr-first'>
                        <td>1st</td>
                        <td>2A</td>
                        <td>3500</td>
                        <td>5000</td>
                        <td></td>
                        <td></td>
                        <td>2A</td>
                        <td>2500</td>
                        <td>3500</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td>2B</td>
                        <td>1200</td>
                        <td>2400</td>
                        <td></td>
                        <td></td>
                        <td>2B</td>
                        <td>1000</td>
                        <td>2000</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td>2C</td>
                        <td>600</td>
                        <td>1200</td>
                        <td></td>
                        <td></td>
                        <td>2C</td>
                        <td>500</td>
                        <td>1000</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>SPC</td>
                        <td>2D</td>
                        <td>250</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>2D</td>
                        <td>200</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>CON</td>
                        <td>2E</td>
                        <td>80</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>2E</td>
                        <td>60</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class='playout-table-con'>
            <table>
                    <tr>
                        <th colspan="6" class="table-header"><span>TOTO</span><span>5D</span><span>6D</span></th>
                        <th colspan="5" class="table-header"><span>6D</span></th>
                    </tr>
                    <tr class='tr-first'>
                        <td>1st</td>
                        <td></td>
                        <td>16500</td>
                        <td>110000</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>150000</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>2nd</td>
                        <td></td>
                        <td>5500</td>
                        <td>3300</td>
                        <td></td>
                        <td></td>
                         <td></td>
                        <td>5000</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>3rd</td>
                        <td></td>
                        <td>3300</td>
                        <td>330</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>500</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>4th</td>
                        <td></td>
                        <td>550</td>
                        <td>33</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>50</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>5th</td>
                        <td></td>
                        <td>22</td>
                        <td>4.4</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>5</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>5th</td>
                        <td></td>
                        <td>5.5</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div> -->
            <!-- new -->
            <!-- <div class='new-playout-table-con'>
                <div class="tb-title">
                    <img class="gd" src="@/assets/images/GD-title.png" alt="" />
                </div>
                <table cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="yellow">4D</td>
                        <td class="yellow">BIG</td>
                        <td class="yellow">SMALL</td>
                        <td class="yellow">SA</td>
                        <td class="yellow">3A</td>
                        <td class="yellow">3F</td>
                    </tr>
                    <tr>
                        <td class="yellow">1ST</td>
                        <td class="white">2625</td>
                        <td class="white">3675</td>
                        <td class="white">6300</td>
                        <td class="white">639</td>
                        <td class="white">231</td>
                    </tr>
                    <tr>
                        <td class="yellow">2ND</td>
                        <td class="white">1050</td>
                        <td class="white">2100</td>
                        <td></td>
                        <td></td>
                        <td class="white">231</td>
                    </tr>
                    <tr>
                        <td class="yellow">3RD</td>
                        <td class="white">525</td>
                        <td class="white">1050</td>
                        <td></td>
                        <td></td>
                        <td class="white">231</td>
                    </tr>
                    <tr>
                        <td class="yellow">SPC</td>
                        <td class="white">210</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td class="yellow">CON</td>
                        <td class="white">63</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div> 
            <div class='new-playout-table-con'>
                <div class="tb-title">
                    <img class="nl" src="@/assets/images/nine-lotto-title.png" alt="" />
                </div>
                <table cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="yellow">4D</td>
                        <td class="yellow">BIG</td>
                        <td class="yellow">SMALL</td>
                        <td class="yellow">SA</td>
                        <td class="yellow">3A</td>
                        <td class="yellow">3F</td>
                    </tr>
                    <tr>
                        <td class="yellow">1ST</td>
                        <td class="white">3000</td>
                        <td class="white">4000</td>
                        <td class="white">7000</td>
                        <td class="white">720</td>
                        <td class="white">240</td>
                    </tr>
                    <tr>
                        <td class="yellow">2ND</td>
                        <td class="white">1000</td>
                        <td class="white">2000</td>
                        <td></td>
                        <td></td>
                        <td class="white">240</td>
                    </tr>
                    <tr>
                        <td class="yellow">3RD</td>
                        <td class="white">500</td>
                        <td class="white">1000</td>
                        <td></td>
                        <td></td>
                        <td class="white">240</td>
                    </tr>
                    <tr>
                        <td class="yellow">SPC</td>
                        <td class="white">200</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td class="yellow">CON</td>
                        <td class="white">60</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class='new-playout-table-con'>
                <div class="tb-title">
                    <img class="mkt" src="@/assets/images/mkt-title.png" alt="" />
                </div>
                <table cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="yellow">4D</td>
                        <td class="yellow">BIG</td>
                        <td class="yellow">SMALL</td>
                        <td class="yellow">SA</td>
                        <td class="yellow">3A</td>
                        <td class="yellow">3F</td>
                    </tr>
                    <tr>
                        <td class="yellow">1ST</td>
                        <td class="white">3500</td>
                        <td class="white">5000</td>
                        <td class="white">8000</td>
                        <td class="white">840</td>
                        <td class="white">280</td>
                    </tr>
                    <tr>
                        <td class="yellow">2ND</td>
                        <td class="white">1200</td>
                        <td class="white">2400</td>
                        <td></td>
                        <td></td>
                        <td class="white">280</td>
                    </tr>
                    <tr>
                        <td class="yellow">3RD</td>
                        <td class="white">600</td>
                        <td class="white">1200</td>
                        <td></td>
                        <td></td>
                        <td class="white">280</td>
                    </tr>
                    <tr>
                        <td class="yellow">SPC</td>
                        <td class="white">250</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td class="yellow">CON</td>
                        <td class="white">80</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <div class='new-playout-table-con'>
                <div class="tb-title d6">
                    <img class="d6" src="@/assets/images/6d-title.png" alt="" />
                </div>
                <table cellspacing="0" cellpadding="0">
                    <tr>
                        <td class="yellow"></td>
                        <td class="yellow">TOTO 5D</td>
                        <td class="yellow">TOTO 6D</td>
                        <td class="yellow">GD 6D</td>
                        <td class="yellow">9 LOTTO 6D</td>
                    </tr>
                    <tr>
                        <td class="yellow">1ST</td>
                        <td class="white">16500</td>
                        <td class="white">110000</td>
                        <td class="white">150000</td>
                        <td class="white">100000</td>
                    
                    </tr>
                    <tr>
                        <td class="yellow">2ND</td>
                        <td class="white">5500</td>
                        <td class="white">3300</td>
                        <td class="white">5000</td>
                        <td class="white">3000</td>
                       
                    </tr>
                    <tr>
                        <td class="yellow">3RD</td>
                        <td class="white">3300</td>
                        <td class="white">330</td>
                        <td class="white">500</td>
                        <td class="white">300</td>
                    
                    </tr>
                    <tr>
                        <td class="yellow">4TH</td>
                        <td class="white">550</td>
                        <td class="white">33</td>
                        <td class="white">50</td>
                        <td class="white">30</td>
                    
                    </tr>
                    <tr>
                        <td class="yellow">5TH</td>
                        <td class="white">22</td>
                        <td class="white">4.4</td>
                        <td class="white">5</td>
                        <td class="white">4</td>
                    
                    </tr>
                    <tr>
                        <td class="yellow">6TH</td>
                        <td class="white">5.5</td>
                        <td></td>
                        <td></td>
                        <td></td>
                       
                    </tr>
                </table>
           
            </div> -->
            <!-- new -->
            <div class="playout-con playout-tb">
                <img class="home-playout-table" src="@/assets/images/马票1-home-adjusted.png">
            </div>
          
        </div>
        
        
        <div class='payout-icon'>
          <img src="@/assets/images/logo/products/magnum.png" />
          <img src="@/assets/images/logo/products/damacai.png" />
          <img src="@/assets/images/logo/products/sportstoto.png" />
          <img src="@/assets/images/logo/products/sg4d.png" />
          <img src="@/assets/images/logo/products/88.png" />
          <img src="@/assets/images/logo/products/stc.png" />
          <img src="@/assets/images/logo/products/cashsweep.png" />
          <img src="@/assets/images/logo/products/gd.png" />
          <img src="@/assets/images/logo/products/logo-nl9.png" />
          
        </div>
      </div>
    </div>






</template>
<style type="text/css">
.new-playout-table-con {
    width: 100%;
    font-weight: bold;
    margin-top: 35px;
}
.new-playout-table-con .tb-title {
    background-color: #979797;
    border: 1px solid #000;
    border-radius: 5px;
    height: 45px;
}
.new-playout-table-con .tb-title img.gd {
    width: 345px;
    padding-left: 5px;
    position: relative;
    top: -20px;
}
.new-playout-table-con .tb-title img.nl {
    width: 300px;
    padding-left: 5px;
    position: relative;
    top: -20px;
}
.new-playout-table-con .tb-title img.mkt {
    width: 370px;
    padding-left: 5px;
    position: relative;
    top: -20px;
}
.new-playout-table-con .tb-title.d6 {
    height: 35px;
}
.new-playout-table-con .tb-title img.d6 {
    width: 370px;
    padding-left: 5px;
    position: relative;
    top: -20px;
}
.new-playout-table-con table {
    width: 100%;
    table-layout: fixed;
    border: 0;
    border-collapse: unset;
    padding: 0;
}
.new-playout-table-con table td {
    width: 100%;
    padding: 0;
    border: 1px solid #000;
    border-radius: 5px !important;
    font-size: 15px;
    background-color: #979797;
}
.new-playout-table-con table td.yellow {
    background-color: #ebb43f;
}
.new-playout-table-con table td.white {
    background-color: #e3e3e3;
}
img.home-playout-table {
    width: 100%;
}
@media only screen and (max-width: 360px) {
    .new-playout-table-con .tb-title img.d6,.new-playout-table-con .tb-title img.mkt {
        width: 325px;
    }
}

</style>
<script>
  import BackButton from '@/components/headers/BackButton.vue'
   import moment from 'moment'

  export default {
      name: "Home",
      components: {BackButton},
      data() {
        return {
            userBalance: 0,
            locales: { "English": "en",
                       "Bahasa Melayu": "ms"
                },
            showMultilingualModal:false,
            today: moment().format('YYYY-MM-DD'),
        }
      },
      methods: {
          async getBalance() {

                let _this = this;

                await this.$axios.post('/web/user/getLatestBalance',{}).then(function(response){
                    _this.userBalance = parseFloat(response.data.return.userBalance).toFixed(2);
                });
          },
          checkeLanguage(lang){
            console.log(lang.target.value);

          }
      },
      mounted: function () {
        this.getBalance();
      }
  };
</script>
